import React from 'react';

import { deleteDocuments } from 'services/documents/api';
import { Errors, validateYup } from 'services/forms/validation';
import { PermissionType } from 'services/permissions';
import {
  createSalesOrder,
  SalesOrder,
  SalesOrderBundleItem,
  SalesOrderItem,
  SalesOrderItemStatus,
  SalesOrderItemTypes,
  SalesOrderStatus,
  updateSalesOrder,
} from 'services/salesOrders';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import { toMulticurrencyCalculate } from 'helpers';

import { orderLineNumbers } from './components/SalesOrderItems/transformations';
import { SalesOrderActiveIdState } from './types';
import {
  salesOrderItemsRowFormValidation,
  salesOrderSchema,
} from './validations';
import { logErrorCtx } from 'app/logging';

export const shouldShowUnissue = (salesStatus: SalesOrderStatus) =>
  salesStatus === SalesOrderStatus.Issued;

export const shouldShowQuickFulfill = (salesOrder: SalesOrder) => {
  const nonCreditReturnOrNonDropShipItems = salesOrder.salesItems.filter(
    (s) =>
      s.salesOrderItemType !== SalesOrderItemTypes.DropShip &&
      s.salesOrderItemType !== SalesOrderItemTypes.CreditReturn
  );
  const isAllNonDropShipOrCreditReturnItemsFulfilled =
    nonCreditReturnOrNonDropShipItems.every(
      (item) => item.status === SalesOrderItemStatus.Fulfilled
    );

  const isAllCreditReturnOrDropShipItems = salesOrder.salesItems.every(
    (s) =>
      s.salesOrderItemType === SalesOrderItemTypes.CreditReturn ||
      s.salesOrderItemType === SalesOrderItemTypes.MiscReturn ||
      s.salesOrderItemType === SalesOrderItemTypes.DropShip ||
      isAllNonDropShipOrCreditReturnItemsFulfilled
  );

  const deletedItems = salesOrder.salesItems.filter((item) => item.deleted);

  return (
    (salesOrder.status === SalesOrderStatus.Issued ||
      salesOrder.status === SalesOrderStatus.PartiallyFulfilled) &&
    salesOrder.id &&
    salesOrder.id > 0 &&
    salesOrder.salesItems.length &&
    salesOrder.salesItems.length !== deletedItems.length &&
    !isAllCreditReturnOrDropShipItems
  );
};

export const shouldShowSave = (salesStatus: SalesOrderStatus) =>
  salesStatus === SalesOrderStatus.Estimate ||
  salesStatus === SalesOrderStatus.Issued ||
  salesStatus === SalesOrderStatus.PartiallyPicked ||
  salesStatus === SalesOrderStatus.PartiallyFulfilled ||
  salesStatus === SalesOrderStatus.Picked ||
  salesStatus === SalesOrderStatus.Picking ||
  salesStatus === SalesOrderStatus.Expired;

const duplicateItemsAsCreditReturn = (
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  const notDeletedSoItems = soItems.filter((i) => !i.deleted);

  const resolvedItems = notDeletedSoItems.map((soItem) => {
    if (
      soItem.salesOrderItemType === SalesOrderItemTypes.Bundle ||
      soItem.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
    ) {
      return duplicateBundleItemAsCreditReturn(soItem as SalesOrderBundleItem);
    }
    return duplicateSaleItemAsCreditReturn(soItem);
  });
  const orderedSalesOrderItems = orderLineNumbers(resolvedItems);

  return resolveNewSoItemIndexes(orderedSalesOrderItems);
};

export const duplicateSaleItemAsCreditReturn = (soItem: SalesOrderItem) => {
  const newSoItem: SalesOrderItem = {
    ...soItem,
    status: SalesOrderItemStatus.Entered,
  };

  if (soItem.salesOrderItemType === SalesOrderItemTypes.Sale) {
    return {
      ...newSoItem,
      salesOrderItemType: SalesOrderItemTypes.CreditReturn,
      price: soItem.price ? -soItem.price : 0,
      multiCurrencyItemPrice: soItem.multiCurrencyItemPrice
        ? -soItem.multiCurrencyItemPrice
        : 0,
      multiCurrencyTotal: soItem.multiCurrencyTotal
        ? -soItem.multiCurrencyTotal
        : 0,
      subTotal: soItem.subTotal ? -soItem.subTotal : 0,
    };
  }

  if (soItem.salesOrderItemType === SalesOrderItemTypes.MiscSale) {
    return {
      ...newSoItem,
      salesOrderItemType: SalesOrderItemTypes.MiscReturn,
      price: soItem.price ? -soItem.price : 0,
      multiCurrencyItemPrice: soItem.multiCurrencyItemPrice
        ? -soItem.multiCurrencyItemPrice
        : 0,
      multiCurrencyTotal: soItem.multiCurrencyTotal
        ? -soItem.multiCurrencyTotal
        : 0,
      subTotal: soItem.subTotal ? -soItem.subTotal : 0,
    };
  }

  return newSoItem;
};

export const duplicateBundleItemAsCreditReturn = (
  bundleItem: SalesOrderBundleItem
) => {
  const newBundlePrice =
    bundleItem.price &&
    bundleItem.salesOrderItemType !== SalesOrderItemTypes.BundleCreditReturn
      ? -bundleItem.price
      : bundleItem.price || 0;

  return {
    ...bundleItem,
    salesOrderItemType: SalesOrderItemTypes.BundleCreditReturn,
    status: SalesOrderItemStatus.Entered,
    price: newBundlePrice,
    salesOrderItems: bundleItem.salesOrderItems.map((soi) =>
      duplicateSaleItemAsCreditReturn(soi)
    ),
  };
};

const resolveNewSoItemIndexes = (
  soItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  let lastIndex = 0;

  // iterate over all sales order items and bundle items and set negative ids
  const resolvedItems = soItems.map((soi) => {
    lastIndex++;
    const newItem = { ...soi, id: -lastIndex };
    // if the item is a bundle iterate over its sales items using lastIndex
    if (
      soi.salesOrderItemType === SalesOrderItemTypes.Bundle ||
      soi.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
    ) {
      const newSoItems = (soi as SalesOrderBundleItem).salesOrderItems.map(
        (si) => {
          lastIndex++;
          return { ...si, id: -lastIndex };
        }
      );
      return { ...newItem, salesOrderItems: newSoItems };
    }

    return newItem;
  });
  return resolvedItems;
};

export const transformToDuplicatedItem = (
  so: SalesOrder,
  nextSalesOrdersNumber: number
): SalesOrder => {
  return {
    ...so,
    id: SalesOrderActiveIdState.Duplicate,
    number:
      nextSalesOrdersNumber === -1 ? null : nextSalesOrdersNumber.toString(),
    status: SalesOrderStatus.Estimate,
    salesItems: so.salesItems.map((s, index) => {
      const salesOrderItem = {
        ...s,
        id: -index - 1,
        salesOrderId: null,
        status: SalesOrderItemStatus.Entered,
        version: null,
      };

      if (
        s.salesOrderItemType === SalesOrderItemTypes.Bundle ||
        s.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
      ) {
        return {
          ...salesOrderItem,
          salesOrderItems: (s as SalesOrderBundleItem).salesOrderItems.map(
            (soi, soiIndex) => ({
              ...soi,
              id: -soiIndex - 1,
              salesOrderId: null,
              status: SalesOrderItemStatus.Entered,
              version: null,
            })
          ),
        };
      }
      return salesOrderItem;
    }),
  };
};

export const transformToDuplicateAsCreditReturn = (
  so: SalesOrder
): SalesOrder => {
  return {
    ...so,
    id: SalesOrderActiveIdState.DuplicateAsCreditReturn,
    number: `${so.number} - Return`,
    status: SalesOrderStatus.Estimate,
    salesItems: duplicateItemsAsCreditReturn(so.salesItems),
  };
};

export const editSalesOrderPermissions = (so: SalesOrder): PermissionType[] => {
  if (so.deleted) {
    return [PermissionType.Undelete];
  }
  return so.id && so.id > 0
    ? [PermissionType.SalesOrderEdit]
    : [PermissionType.SalesOrderCreate];
};

export const validateSoItems = (
  soItems: SalesOrderItem[],
  setRowValidationErrors: React.Dispatch<React.SetStateAction<Errors[]>>
) => {
  const newErrors: Errors[] = [];

  const customSetter = (index: number) => (error: Errors) => {
    newErrors[index] = error;
  };

  const itemsAreValid = soItems
    .map((item, index) => {
      const itemSpecificSetter = customSetter(index);
      return validateYup(
        item,
        salesOrderItemsRowFormValidation(item),
        itemSpecificSetter
      );
    })
    .every((i) => i);

  setRowValidationErrors(newErrors);

  return itemsAreValid;
};

export const validateSalesOrder = (
  salesOrder: SalesOrder,
  setErrors: React.Dispatch<React.SetStateAction<Errors>>,
  setRowErrors: React.Dispatch<React.SetStateAction<Errors[]>>,
  setCustomFieldsErrors: React.Dispatch<React.SetStateAction<Errors>>
) => {
  const isValid = validateYup(salesOrder, salesOrderSchema, setErrors);

  const areSoItemsValid = validateSoItems(
    salesOrder.salesItems.filter((i) => !i.deleted),
    setRowErrors
  );

  const isCustomFieldsValid = validateYup(
    salesOrder.customFields,
    customFieldsYupSchema,
    setCustomFieldsErrors
  );

  return isValid && areSoItemsValid && isCustomFieldsValid;
};

export const saveSalesOrder = async (
  salesOrder: SalesOrder,
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder>>,
  hideSnack: boolean = false
) => {
  const notDeletedDocuments = salesOrder.documents.filter((d) => !d.deleted);
  const deletedDocuments = salesOrder.documents.filter((d) => d.deleted);
  const resolvedSalesOrder = {
    ...salesOrder,
    documents: notDeletedDocuments,
  };

  // CREATE
  if (!resolvedSalesOrder.id || resolvedSalesOrder.id < 0) {
    try {
      await deleteDocuments(deletedDocuments);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error while deleting documents', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description:
          'Error while deleting documents when creating a new sales order',
        component: 'SalesOrderDetailsCard',
      });
    }
    const newSalesOrder = await createSalesOrder(resolvedSalesOrder, hideSnack);
    setSalesOrder(newSalesOrder);
    return newSalesOrder;
  }

  // UPDATE
  try {
    await deleteDocuments(deletedDocuments);
  } catch (err) {
    const error = err as Error;
    logErrorCtx('Documents Not Deleted', {
      error,
      stackTrace: error.stack,
      title: 'Unable to Delete Documents',
      description: 'Error thrown from `deleteDocuments()` method',
    });
  }

  const newSalesOrder = await updateSalesOrder(resolvedSalesOrder, hideSnack);
  setSalesOrder(newSalesOrder);

  return newSalesOrder;
};

export const calculateMultiCurrencyFields = (
  salesOrder: SalesOrder,
  exchangeRate: number
): SalesOrder => {
  return {
    ...salesOrder,
    exchangeRate,
    salesItems: salesOrder.salesItems.map((i) => {
      return {
        ...i,
        exchangeRate,
        multiCurrencyItemPrice: (i.price || 0) * exchangeRate,
        multiCurrencyTotal: toMulticurrencyCalculate(i.total, exchangeRate),
        multiCurrencyTaxTotal: toMulticurrencyCalculate(
          i.taxTotal,
          exchangeRate
        ),
        multiCurrencyDiscountTotal: toMulticurrencyCalculate(
          i.discountTotal,
          exchangeRate
        ),
        multiCurrencySubTotal: toMulticurrencyCalculate(
          i.subTotal,
          exchangeRate
        ),
      };
    }),
  };
};

export const useDisableSalesOrderField =
  (salesOrderStatus: SalesOrderStatus) => (fieldName: string) => {
    switch (salesOrderStatus) {
      case SalesOrderStatus.Estimate:
        return false;

      case SalesOrderStatus.Cancelled:
        return true;

      case SalesOrderStatus.Issued:
        return ['location', 'salesRepresentative', 'paymentTerms'].includes(
          fieldName
        );

      case SalesOrderStatus.Picking:
      case SalesOrderStatus.PartiallyPicked:
      case SalesOrderStatus.Picked:
      case SalesOrderStatus.PartiallyFulfilled:
        return [
          'customer',
          'customerPoNumber',
          'location',
          'salesRepresentative',
          'paymentTerms',
        ].includes(fieldName);

      case SalesOrderStatus.Fulfilled:
        return true;

      case SalesOrderStatus.ClosedShort:
        return true;

      case SalesOrderStatus.Voided:
        return true;

      case SalesOrderStatus.Expired:
        return true;

      default:
        return false; // default is 'enabled'
    }
  };
