import _ from 'lodash';

import { transformVendor } from 'services/vendors';
import { transformFob, transformItem } from 'services/items';
import {
  transformDateToMomentDate,
  roundToDecimals,
  countDecimals,
} from 'helpers';
import { transformLocation } from 'services/locations';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';
import { transformTaxRate } from 'services/taxRates';
import {
  transformDocument,
  transformToRequestDocument,
} from 'services/documents';

import {
  AutoPurchaseOrderItem,
  PurchaseOrder,
  PurchaseOrderItem,
} from './types';

/**
 * Transforms response to PurchaseOrder
 * @param response
 */
export const transformPurchaseOrder = (response: any): PurchaseOrder => {
  const purchaseOrderItemList = response.purchaseOrderItems || [];
  const sortedPurchaseOrderItemList = _.sortBy(
    purchaseOrderItemList.map((i: any) => transformPurchaseOrderItem(i)),
    ['lineNumber']
  );
  const location = response.location
    ? transformLocation(response.location)
    : null;
  const customFields = response.customFields || [];
  const picks = response.picks || [];
  const shipments = response.shipments || [];
  const documents = response.documents || [];

  return {
    id: response.id,
    accountingClassId: response.accountingClassId,
    buyer: response.buyer,
    buyerId: response.buyerId,
    carrierId: response.carrierId,
    carrierServiceId: response.carrierServiceId,
    costIncludesTax: response.costIncludesTax,
    customerSONumber: response.customerSONumber,
    customFields: customFields.map(transformCustomField),
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    dateFulfilled: transformDateToMomentDate(response.dateFulfilled),
    dateIssued: transformDateToMomentDate(response.dateIssued),
    dateScheduled: transformDateToMomentDate(response.dateScheduled),
    deleted: response.deleted,
    documents: documents.map(transformDocument),
    dropShipSalesOrder: response.dropShipSalesOrder,
    email: response.email,
    exchangeCurrencyId: response.exchangeCurrencyId,
    exchangeRate: response.exchangeRate,
    exchangeCurrency: response.exchangeCurrency,
    fobPoint: response.fobPoint ? transformFob(response.fobPoint) : null,
    fobPointId: response.fobPointId,
    grandTotal: response.grandTotal,
    homeCurrencyId: response.homeCurrencyId,
    location,
    locationId: response.locationId,
    multiCurrencyGrandTotal: response.multiCurrencyGrandTotal,
    notes: response.notes,
    number: response.number,
    paymentTermId: response.paymentTermId,
    phone: response.phone,
    picks,
    purchaseTax: response.purchaseTax
      ? transformTaxRate(response.purchaseTax)
      : null,
    purchaseTaxId: response.purchaseTaxId,
    purchaseOrderItemList: sortedPurchaseOrderItemList,
    receipt: response.receipt,
    shipments,
    shippingTermId: response.shippingTermId,
    shipToAttention: response.shipToAttention,
    shipToCity: response.shipToCity,
    shipToCompanyName: response.shipToCompanyName,
    shipToCountry: response.shipToCountry,
    shipToName: response.shipToName,
    shipToPostalCode: response.shipToPostalCode,
    shipToResidential: response.shipToResidential,
    shipToState: response.shipToState,
    shipToStreet: response.shipToStreet,
    shipToStreet2: response.shipToStreet2,
    shipToVerified: response.shipToVerified,
    status: response.purchaseOrderStatus,
    vendor: response.vendor ? transformVendor(response.vendor) : null,
    vendorAddressCity: response.vendorAddressCity,
    vendorAddressCountry: response.vendorAddressCountry,
    vendorAddressName: response.vendorAddressName,
    vendorAddressPostalCode: response.vendorAddressPostalCode,
    vendorAddressState: response.vendorAddressState,
    vendorAddressStreet: response.vendorAddressStreet,
    vendorAddressStreet2: response.vendorAddressStreet2,
    vendorAddressVerified: response.vendorAddressVerified,
    vendorAttention: response.vendorAttention,
    vendorCompanyName: response.vendorCompanyName,
    vendorId: response.vendorId,
    vendorResidential: response.vendorResidential,
    vendorSONumber: response.vendorSONumber,
    version: response.version,
  };
};

/**
 * Transform response to PurchaseOrderItem
 * @param response
 */
export const transformPurchaseOrderItem = (
  response: any
): PurchaseOrderItem => {
  const multiCurrencyTotalCost = response.multiCurrencyTotalCost
    ? roundToDecimals(parseFloat(response.multiCurrencyTotalCost), 2)
    : null;

  return {
    id: response.id,
    customer: response.customer,
    customerId: response.customerId,
    dateScheduled: transformDateToMomentDate(response.dateScheduled),
    deleted: response.deleted,
    description: response.description,
    item: response.item ? transformItem(response.item) : null,
    itemId: response.itemId,
    lineNumber: response.lineNumber,
    multiCurrencyTotalCost,
    multiCurrencyUnitCost: response.multiCurrencyUnitCost,
    name: response.name,
    notes: response.notes,
    purchaseOrderId: response.purchaseOrderId,
    status: response.purchaseOrderItemStatus,
    purchaseOrderItemType: response.purchaseOrderItemType,
    purchaseOrder: response.purchaseOrder
      ? transformPurchaseOrder(response.purchaseOrder)
      : null,
    quantity: response.quantity,
    quantityFulfilled: response.quantityFulfilled,
    totalCost: response.totalCost,
    isFiveDecimals: response.unitCost
      ? countDecimals(parseFloat(response.unitCost)) > 2
      : false,
    unitCost: response.unitCost,
    uom: response.uom,
    uomId: response.uomId,
    vendorItemName: response.vendorItemName,
    version: response.version,
    exchangeRate: response.exchangeRate,
    exchangeCurrencyId: response.exchangeCurrencyId,
    exchangeCurrency: response.exchangeCurrency,
    taxable: response.taxable,
    taxId: response.taxId,
    taxRate: response.taxRate,
    taxTotal: roundToDecimals(response.taxTotal || 0, 2),
    subTotal: response.subTotal,
    multiCurrencySubTotal: response.multiCurrencySubTotal,
    costIncludeTax: response.costIncludesTax,
    multiCurrencyTaxTotal: roundToDecimals(
      response.multiCurrencyTaxTotal || 0,
      2
    ),
  };
};

/**
 * Transform PurchaseOrder to POST data
 * @param purchaseOrder
 */
export const transformPoToPostPo = (purchaseOrder: PurchaseOrder): any => {
  return {
    accountingClassId: purchaseOrder.accountingClassId,
    buyerId: purchaseOrder.buyerId,
    carrierId: purchaseOrder.carrierId,
    carrierServiceId: purchaseOrder.carrierServiceId,
    customerSONumber: purchaseOrder.customerSONumber,
    customFields: purchaseOrder.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.PurchaseOrder, true)
    ),
    dateScheduled: purchaseOrder.dateScheduled,
    deleted: purchaseOrder.deleted,
    documents: purchaseOrder.documents.map(transformToRequestDocument),
    email: purchaseOrder.email,
    exchangeCurrencyId: purchaseOrder.exchangeCurrencyId,
    exchangeRate: purchaseOrder.exchangeRate,
    locationId: purchaseOrder.locationId,
    number: purchaseOrder.number,
    phone: purchaseOrder.phone,
    notes: purchaseOrder.notes,
    fobPointId: purchaseOrder.fobPointId,
    paymentTermId: purchaseOrder.paymentTermId,
    shipToAttention: purchaseOrder.shipToAttention,
    shipToResidential: purchaseOrder.shipToResidential,
    shippingTermId: purchaseOrder.shippingTermId,
    shipToCity: purchaseOrder.shipToCity,
    shipToCountry: purchaseOrder.shipToCountry,
    shipToName: purchaseOrder.shipToName,
    shipToPostalCode: purchaseOrder.shipToPostalCode,
    shipToState: purchaseOrder.shipToState,
    shipToStreet: purchaseOrder.shipToStreet,
    shipToStreet2: purchaseOrder.shipToStreet2,
    shipToCompanyName: purchaseOrder.shipToCompanyName,
    shipToVerified: purchaseOrder.shipToVerified,
    vendorAttention: purchaseOrder.vendorAttention,
    vendorResidential: purchaseOrder.vendorResidential,
    vendorAddressCity: purchaseOrder.vendorAddressCity,
    vendorAddressCountry: purchaseOrder.vendorAddressCountry,
    vendorAddressName: purchaseOrder.vendorAddressName,
    vendorAddressPostalCode: purchaseOrder.vendorAddressPostalCode,
    vendorAddressState: purchaseOrder.vendorAddressState,
    vendorAddressStreet: purchaseOrder.vendorAddressStreet,
    vendorAddressStreet2: purchaseOrder.vendorAddressStreet2,
    vendorAddressVerified: purchaseOrder.vendorAddressVerified,
    vendorCompanyName: purchaseOrder.vendorCompanyName,
    vendorId: purchaseOrder.vendorId,
    vendorSONumber: purchaseOrder.vendorSONumber,
    purchaseTaxId: purchaseOrder.purchaseTaxId,
    purchaseOrderItems: purchaseOrder.purchaseOrderItemList.map((i) =>
      transformPoItemToPutPoItem(i)
    ),
  };
};

/**
 * Transform PurchaseOrder to PUT data
 * @param purchaseOrder
 */
export const transformPoToPutPo = (purchaseOrder: PurchaseOrder): any => {
  return {
    accountingClassId: purchaseOrder.accountingClassId,
    buyerId: purchaseOrder.buyerId,
    carrierId: purchaseOrder.carrierId,
    carrierServiceId: purchaseOrder.carrierServiceId,
    customerSONumber: purchaseOrder.customerSONumber,
    customFields: purchaseOrder.customFields.map((c) =>
      transformToRequestCustomField(c, ObjectType.PurchaseOrder, true)
    ),
    dateScheduled: purchaseOrder.dateScheduled,
    deleted: purchaseOrder.deleted,
    documents: purchaseOrder.documents.map(transformToRequestDocument),
    email: purchaseOrder.email,
    exchangeCurrencyId: purchaseOrder.exchangeCurrencyId,
    exchangeRate: purchaseOrder.exchangeRate,
    id: purchaseOrder.id,
    locationId: purchaseOrder.locationId,
    number: purchaseOrder.number,
    phone: purchaseOrder.phone,
    notes: purchaseOrder.notes,
    fobPointId: purchaseOrder.fobPointId,
    paymentTermId: purchaseOrder.paymentTermId,
    shipToAttention: purchaseOrder.shipToAttention,
    shipToResidential: purchaseOrder.shipToResidential,
    shippingTermId: purchaseOrder.shippingTermId,
    shipToCity: purchaseOrder.shipToCity,
    shipToCountry: purchaseOrder.shipToCountry,
    shipToName: purchaseOrder.shipToName,
    shipToPostalCode: purchaseOrder.shipToPostalCode,
    shipToState: purchaseOrder.shipToState,
    shipToStreet: purchaseOrder.shipToStreet,
    shipToStreet2: purchaseOrder.shipToStreet2,
    shipToCompanyName: purchaseOrder.shipToCompanyName,
    shipToVerified: purchaseOrder.shipToVerified,
    vendorAttention: purchaseOrder.vendorAttention,
    vendorResidential: purchaseOrder.vendorResidential,
    vendorAddressCity: purchaseOrder.vendorAddressCity,
    vendorAddressCountry: purchaseOrder.vendorAddressCountry,
    vendorAddressName: purchaseOrder.vendorAddressName,
    vendorAddressPostalCode: purchaseOrder.vendorAddressPostalCode,
    vendorAddressState: purchaseOrder.vendorAddressState,
    vendorAddressStreet: purchaseOrder.vendorAddressStreet,
    vendorAddressStreet2: purchaseOrder.vendorAddressStreet2,
    vendorAddressVerified: purchaseOrder.vendorAddressVerified,
    vendorCompanyName: purchaseOrder.vendorCompanyName,
    vendorId: purchaseOrder.vendorId,
    vendorSONumber: purchaseOrder.vendorSONumber,
    version: purchaseOrder.version,
    purchaseTaxId: purchaseOrder.purchaseTaxId,
    purchaseOrderItems: purchaseOrder.purchaseOrderItemList.map((i) =>
      transformPoItemToPutPoItem(i)
    ),
  };
};

/**
 * Transform PurchaseOrderItem to PUT data
 * @param purchaseOrderItem
 */
export const transformPoItemToPutPoItem = (
  purchaseOrderItem: PurchaseOrderItem
): any => {
  const requestData = {
    lineNumber: purchaseOrderItem.lineNumber,
    name: purchaseOrderItem.name,
    itemId: purchaseOrderItem.itemId,
    purchaseOrderItemType: purchaseOrderItem.purchaseOrderItemType,
    quantity: purchaseOrderItem.quantity,
    uomId: purchaseOrderItem.uomId,
    unitCost: purchaseOrderItem.unitCost,
    notes: purchaseOrderItem.notes,
    description: purchaseOrderItem.description,
    deleted: purchaseOrderItem.deleted,
    vendorItemName: purchaseOrderItem.vendorItemName,
    exchangeRate: purchaseOrderItem.exchangeRate,
    taxId: purchaseOrderItem.taxId,
    taxable: purchaseOrderItem.taxable,
    taxRate: purchaseOrderItem.taxRate,
    taxTotal: purchaseOrderItem.taxTotal,
  };

  // POST REQUEST
  if (purchaseOrderItem.id && purchaseOrderItem.id < 0) {
    return requestData;
  }

  // PUT REQUEST
  return {
    ...requestData,
    id: purchaseOrderItem.id,
    version: purchaseOrderItem.version,
  };
};

export const transformToAutoPoItem = (response: any): AutoPurchaseOrderItem => {
  const vendorItem = response.vendorItems.find(
    (vi: any) => vi.defaultFlag === true
  );

  return {
    id: response.id,
    item: transformItem(response),
    quantity: 0,
    vendorId: vendorItem ? vendorItem.vendorId : null,
  };
};
