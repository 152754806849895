import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';
import * as yup from 'yup';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';

import { initialItemLocation, ItemLocation } from 'services/items';

import { ASSIGNED_LOCATIONS_COLUMNS } from './consts';
import { LocationTabsProps } from './types';
import { editItemPermissions } from '../../helpers';
import {
  findNextNegativeId,
  replaceValueInCollection,
  removeValueFromCollection,
} from 'helpers';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { LocationItemModal } from './components/LocationItemModal';
import { useSelector } from 'react-redux';
import { activeUserHasPermission } from 'services/user/redux';
import { LocationItemRow } from './components/LocationItemRow';
import { showNotification } from 'services/api';
import { Errors, validateYup } from 'services/forms/validation';

import { LocationItemTableRowActions } from './components/LocationItemRow/consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useFlags } from 'helpers/useFlags';

const yupSchema = yup.object().shape({
  parentLocationId: yup.number().required(),
});

const LocationsTab: React.FC<LocationTabsProps> = (props) => {
  const { item, setItem, currentUser } = props;

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const [locationsItem, setLocationsItem] =
    useState<ItemLocation>(initialItemLocation);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [isNewItem, setIsNewItem] = useState(false);
  const [displayLocations, setDisplayLocations] = useState<ItemLocation[]>([]);

  const flags = useFlags();

  const editPermissions = editItemPermissions(item);

  const canEdit = useSelector(activeUserHasPermission(editPermissions));

  useEffect(() => {
    const filteredLocations = flags.newUserEndpoint
      ? item.locationList.filter(
          (location) =>
            location.parentLocationId !== null &&
            currentUser?.locationIdsPermitted?.includes(
              location.parentLocationId
            )
        )
      : item.locationList;
    setDisplayLocations(filteredLocations);
  }, [item, currentUser, flags.newUserEndpoint]);

  const handleAddNewClicked = useCallback(() => {
    setLocationsItem({
      ...initialItemLocation,
      itemId: item.id,
      id: findNextNegativeId(item.locationList),
    });

    setIsNewItem(true);
    setShowLocationsModal(true);
  }, [item.locationList, item.id]);

  const handleDeleteClicked = useCallback(() => {
    const deletedLocationsItems = item.locationList
      .map((i) => ({ ...i, deleted: selectedItems.includes(i.id!) }))
      .filter((i) => !(i.id! < 0 && i.deleted));

    setItem((prevItem) => ({
      ...prevItem,
      locationList: deletedLocationsItems,
    }));

    setSelectedItems([]);
    setDeleteModalVisible(false);
  }, [item.locationList, selectedItems, setItem]);

  const onApplyClicked = useCallback(() => {
    if (!validateYup(locationsItem, yupSchema, setErrors)) {
      return;
    }

    const newLocationsItems = [...item.locationList];

    const locationIndex = newLocationsItems.findIndex((l) => {
      return (
        !l.deleted && l.parentLocationId === locationsItem.parentLocationId
      );
    });

    if (
      _.isNull(locationsItem.pickFromLocationId) &&
      _.isNull(locationsItem.pickToLocationId) &&
      _.isNull(locationsItem.receiveLocationId)
    ) {
      showNotification(
        'Choose at least one option: Pick Origin, Receive, or Pick Destination.',
        {
          variant: 'warning',
        }
      );

      return;
    }

    if (
      locationIndex > -1 &&
      !newLocationsItems[locationIndex].deleted &&
      isNewItem
    ) {
      showNotification('Location already exists', { variant: 'error' });

      return;
    }

    // If location exists
    if (locationIndex > -1) {
      setItem((prevItem) => {
        const itemWithDeleteFlag = newLocationsItems[locationIndex];

        const collectionToReplace = replaceValueInCollection(
          newLocationsItems,
          locationsItem,
          locationIndex
        )!;

        const collectionToPush = [...newLocationsItems, locationsItem];

        return {
          ...prevItem,
          locationList: itemWithDeleteFlag.deleted
            ? collectionToPush
            : collectionToReplace,
        };
      });
      setShowLocationsModal(false);
      return;
    }

    // If location doesn't exist
    newLocationsItems.push(locationsItem);

    setItem((prevItem) => ({
      ...prevItem,
      locationList: newLocationsItems,
    }));
    setShowLocationsModal(false);
  }, [item.locationList, setItem, locationsItem, isNewItem]);

  const handleSelectedChange = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const openModalForEditing = useCallback(
    (id: number) => {
      const activeLocationsItem = item.locationList.find((i) => i.id === id)!;
      setLocationsItem(activeLocationsItem);
      if (canEdit) {
        setShowLocationsModal(true);
      }
      setIsNewItem(false);
    },
    [item.locationList, canEdit]
  );

  const handleAction = useCallback(
    (action: any) => {
      const type: LocationItemTableRowActions = action.type;

      if (type === LocationItemTableRowActions.Delete) {
        setItem((prevItem) => {
          const currentDeletedItem = _.get(
            prevItem,
            `locationList[${action.itemIndex}]`,
            null
          );

          const replacedListItemInCollection =
            replaceValueInCollection(
              prevItem.locationList,
              {
                ...prevItem.locationList[action.itemIndex],
                deleted: true,
              },
              action.itemIndex
            ) || [];

          const removedListItemInCollection = removeValueFromCollection(
            prevItem.locationList,
            action.itemIndex
          );

          return {
            ...prevItem,
            locationList:
              currentDeletedItem.id < 0
                ? removedListItemInCollection
                : replacedListItemInCollection,
          };
        });
      }
    },
    [setItem]
  );

  /*
   * Remove error border around Parent location when new location is chosen.
   * */
  useEffect(() => {
    const parentLocation = _.get(locationsItem, 'parentLocation', false);
    const parentLocationError = _.get(errors, 'parentLocationId', false);

    if (parentLocationError && parentLocation) {
      setErrors({});
    }
  }, [locationsItem, errors, setErrors]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="auto"
      width="100%"
      overflow="hidden"
    >
      <FBOTitleBar title="Assigned Locations">
        {!_.isEmpty(selectedItems) && (
          <FBOButton
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="item-locations-delete"
            onClick={() => setDeleteModalVisible(true)}
            permissions={editPermissions}
          >
            Delete
          </FBOButton>
        )}
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="item-locations-add-new"
          icon="FBOAddCircle"
          onClick={handleAddNewClicked}
          permissions={editPermissions}
        >
          Add New
        </FBOButton>
      </FBOTitleBar>

      <ItemsTable
        data={displayLocations}
        columns={ASSIGNED_LOCATIONS_COLUMNS}
        filterRows={(i) => !i.deleted}
        RenderCustomRow={LocationItemRow}
        onItemClick={openModalForEditing}
        onSelectedChange={handleSelectedChange}
        selectedItems={selectedItems}
        onAction={handleAction}
        dataQa="locations-item-tab-table"
        tableLayoutFixed
        emptyTableText="ADD NEW ENTRY BY PRESSING 'ADD'"
      />
      <LocationItemModal
        show={showLocationsModal}
        locationsItem={locationsItem}
        setLocationsItem={setLocationsItem}
        onCancelClicked={() => setShowLocationsModal(false)}
        onApplyClicked={onApplyClicked}
        editPermissions={editPermissions}
        errors={errors}
        isNewItem={isNewItem}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Checked Item"
        body={`This will delete selected items, are you sure?`}
        onCancelClicked={() => setDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteClicked}
        confirmLabel="Delete"
        confirmButtonRed
      />
    </Box>
  );
};

export default memo(LocationsTab);
