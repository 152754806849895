import React, { memo, useCallback, useMemo } from 'react';
import { TableCell, Box } from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { TextField } from 'ui/components/TextField/TextField';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { getVendors, Vendor } from 'services/vendors';
import { ItemReorderPoint } from 'services/items';
import { InventoryLocationSummary } from 'services/inventory';

const ItemsStepRow: React.FC<RenderCustomRowProps> = (props) => {
  const { row, setData, index, errors } = props;

  const { items: vendors } = useSelector(getVendors);

  const item = row.item;

  const itemUom = _.get(item, 'defaultUom.abbreviation', '');

  const selectedVendor = useMemo(() => {
    if (row.vendorId) {
      return vendors.find((v) => v.id === row.vendorId) || null;
    }
    return null;
  }, [row, vendors]);

  const inventoryLocationSummary: InventoryLocationSummary | null = useMemo(
    () =>
      item.inventoryLocationSummaryList[0]
        ? item.inventoryLocationSummaryList[0]
        : null,
    [item.inventoryLocationSummaryList]
  );

  const reorderPoint: ItemReorderPoint | null = useMemo(
    () => (item.reorderPointsList[0] ? item.reorderPointsList[0] : null),
    [item.reorderPointsList]
  );
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...row, [e.target.name]: e.target.value || null }, index);
  };

  const handleVendorChange = useCallback(
    (vendor: Vendor | null) => {
      setData({ ...row, vendorId: vendor ? vendor.id : null }, index);
    },
    [row, index, setData]
  );
  return (
    <>
      <TableCell>
        <Box>
          <ImageItemBox
            source={_.get(item, 'defaultImage', null)}
            name={_.get(item, 'name', null)}
            description={_.get(item, 'description', null)}
            dataQa="auto-order"
          />
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-onhand">
        <Box>
          {inventoryLocationSummary
            ? ` ${inventoryLocationSummary.totalQty}`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-allocated">
        <Box>
          {inventoryLocationSummary
            ? ` ${
                inventoryLocationSummary.allocatedOnPurchaseOrders +
                inventoryLocationSummary.allocatedOnSalesOrders
              }`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-available">
        <Box>
          {inventoryLocationSummary
            ? ` ${inventoryLocationSummary.availableQty}`
            : ''}
        </Box>
      </TableCell>

      <TableCell data-qa="auto-order-incoming">
        <Box>
          {inventoryLocationSummary
            ? ` ${
                inventoryLocationSummary.qtyIncomingOnPurchaseOrders +
                inventoryLocationSummary.qtyIncomingOnSalesOrders
              }`
            : ''}
        </Box>
      </TableCell>
      <TableCell data-qa="auto-order-min">
        <Box>{reorderPoint ? reorderPoint.min : '-'}</Box>
      </TableCell>
      <TableCell data-qa="auto-order-max">
        <Box>{reorderPoint ? reorderPoint.max : '-'}</Box>
      </TableCell>
      <TableCell data-qa="auto-order-UOM">
        <Box>{itemUom}</Box>
      </TableCell>
      <TableCell>
        <Box>
          <TextField
            variant="standard"
            className="redesign"
            type="number"
            placeholder="Quantity"
            name="quantity"
            value={row.quantity}
            onChange={handleTextFieldChange}
            error={!!errors.quantity}
            fullWidth
            dataQa="auto-order-to-order-value"
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          <VendorAutocomplete
            value={selectedVendor}
            onChange={handleVendorChange}
            error={!!errors.vendorId}
            dataQa="auto-order-vendor"
          />
        </Box>
      </TableCell>
    </>
  );
};

export default memo(ItemsStepRow);
