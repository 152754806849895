import _ from 'lodash';
import axios from 'axios';

import { showNotification } from 'services/api/notifications';
import { Pagination } from 'services/search';
import { PaginationDirection } from 'services/url';

import { api, csvApi } from './config';
import { ApiConfig, SnackBarConfig } from './types';
import { methodDisplayNameMapper } from './consts';

/**
 * Function which creates an API call for given api config and associated action types
 */
export const createApiCall =
  (apiConfig: ApiConfig, snackbarConfig?: SnackBarConfig) => async () => {
    const hostname = apiConfig.hostname || api.hostname;
    const path = apiConfig.path;
    const method = _.get(apiConfig, 'method', 'GET');
    const contentType =
      _.includes(['POST', 'PUT', 'PATCH'], method) && !apiConfig.formData
        ? {
            'Content-Type': 'application/json',
          }
        : {};
    const passedHeaders = _.get(apiConfig, 'headers', {});
    const headers = {
      ...contentType,
      ...passedHeaders,
    };
    const fixedTimeout = 60 * 1000;
    const axiosConfig = {
      method,
      headers,
      url: `${hostname}${path}`,
      timeout: apiConfig.timeout ? apiConfig.timeout : fixedTimeout,
      data: apiConfig.body,
    };

    // if there is no snackbar return simple axios call
    if (!snackbarConfig) {
      return axios(axiosConfig);
    }

    // snackbarConfig was provided
    const { getMessage, serviceName } = snackbarConfig as any;
    try {
      const response = await axios(axiosConfig);

      // show success snack bar
      const methodDisplayName = methodDisplayNameMapper[method.toLowerCase()];
      const successMessage = getMessage
        ? getMessage.success && getMessage.success(response)
        : `${serviceName} successfully ${methodDisplayName}`;
      const snackBarMessages = Array.from(
        document.querySelectorAll('[data-qa="client-snackbar-message"]')
      ).map((el) => (el as any).innerText);

      if (successMessage && !snackBarMessages.includes(successMessage)) {
        showNotification(successMessage, { variant: 'success' });
      }

      return response;
    } catch (error) {
      // show error snack bar
      const errorMessage = getMessage
        ? getMessage.error && getMessage.error(error)
        : `${serviceName} encountered error during. Please try again`;

      const snackBarMessages = Array.from(
        document.querySelectorAll('[data-qa="client-snackbar-message"]')
      ).map((el) => (el as any).innerText);

      if (errorMessage && !snackBarMessages.includes(errorMessage)) {
        showNotification(errorMessage, { variant: 'error' });
      }

      throw error;
    }
  };

export const paginatedApiCall = async <T>(
  path: string,
  pagination: Pagination,
  transformation?: (res: any) => T,
  skipPagination: boolean = false
): Promise<{ data: T[]; pagination: Pagination }> => {
  const paginationParts = [
    `orderBy=${pagination.sort.sortBy}`,
    `ascending=${pagination.sort.direction === PaginationDirection.Ascending}`,
    `pageSize=${pagination.pageSize}`,
    `pageNumber=${pagination.page}`,
  ];

  let url = `${api.hostname}${path}${
    path.includes('?') ? '&' : '?'
  }${paginationParts.join('&')}`;
  if (skipPagination) {
    url = `${api.hostname}${path}`;
  }

  const { data } = await axios.get(url);
  if (skipPagination) {
    return {
      data: transformation ? data.data.map(transformation) : data.results,
      pagination: { ...pagination, totalRows: data.maxResults },
    };
  }

  return {
    data: transformation ? data.results.map(transformation) : data.results,
    pagination: { ...pagination, totalRows: data.maxResults },
  };
};

export const paginatedCSVApiCall = async <T>(
  path: string,
  pagination: Pagination,
  transformation?: (res: any) => T
): Promise<{ data: T[]; pagination: Pagination }> => {
  const url = `${csvApi.hostname}${path}`;

  const { data } = await axios.get(url);

  return {
    data: transformation ? data.data.map(transformation) : data.data,
    pagination: { ...pagination, totalRows: data.maxResults },
  };
};

export const paginatedApiCallZeroBased = async <T>(
  path: string,
  pagination: Pagination,
  transformation?: (res: any) => T
): Promise<{ data: T[]; pagination: Pagination }> => {
  const paginationParts = [
    `orderBy=${pagination.sort.sortBy}`,
    `ascending=${pagination.sort.direction === PaginationDirection.Ascending}`,
    `pageSize=${pagination.pageSize}`,
    `pageNumber=${(pagination?.page || 1) - 1}`,
  ];

  const url = `${api.hostname}${path}${
    path.includes('?') ? '&' : '?'
  }${paginationParts.join('&')}`;

  const { data } = await axios.get(url);

  return {
    data: transformation ? data.results.map(transformation) : data.results,
    pagination: { ...pagination, totalRows: data.maxResults },
  };
};

export const paginatedApiCallNewStandard = async <T>(
  path: string,
  pagination: Pagination,
  transformation?: (res: any) => T
): Promise<{ data: T[]; pagination: Pagination }> => {
  const paginationParts = [
    `order.by=${pagination.sort.sortBy}`,
    `order.direction=${
      pagination.sort.direction === PaginationDirection.Ascending
        ? 'ASC'
        : 'DESC'
    }`,
    `page.size=${pagination.pageSize}`,
    `page.number=${(pagination?.page || 1) - 1}`,
  ];

  const url = `${api.hostname}${path}${
    path.includes('?') ? '&' : '?'
  }${paginationParts.join('&')}`;

  const { data } = await axios.get(url);
  return {
    data: transformation ? data.results.map(transformation) : data.results,
    pagination: { ...pagination, totalRows: data.maxResults },
  };
};
export const paginatedApiCallNewStandard2 = async <T>(
  path: string,
  pagination: Pagination,
  transformation?: (res: any) => T
): Promise<{ data: T[]; pagination: Pagination }> => {
  const paginationParts = [
    `order.by=${pagination.sort.sortBy}`,
    `order.direction=${
      pagination.sort.direction === PaginationDirection.Ascending
        ? 'ASC'
        : 'DESC'
    }`,
    `page.size=${pagination.pageSize}`,
    `page.number=${(pagination?.page || 1) - 1}`,
  ];

  const url = `${api.hostname}${path}${
    path.includes('?') ? '&' : '?'
  }${paginationParts.join('&')}`;

  const { data } = await axios.get(url);

  return {
    data: transformation ? data.data.map(transformation) : data.results,
    pagination: { ...pagination, totalRows: data.maxResults },
  };
};
