import React, { useMemo, useCallback, memo } from 'react';
import _ from 'lodash';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { CustomerCarrierAccount } from 'services/customers';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';

import { CarrierAccountAddModalProps } from './types';
import {
  CustomerCarriersAccountType,
  CustomerCarriersBillingType,
} from 'services/shipping';

const types = Object.values(CustomerCarriersBillingType).filter(
  (t) => t !== CustomerCarriersBillingType.Sender
);

const CarrierAccountAddModal: React.FC<CarrierAccountAddModalProps> = (
  props
) => {
  const {
    modalVisible,
    onClose,
    onSaveChanges,
    activeCarrierAccount,
    setActiveCarrierAccount,
    validationErrors,
    isLoading,
  } = props;

  const activeCountry = useMemo(() => {
    return (
      COUNTRIES.find((c) => c.code === activeCarrierAccount.billingCountry) ||
      null
    );
  }, [activeCarrierAccount.billingCountry]);

  const handleTextFieldChange =
    useHandleTextFieldChange<CustomerCarrierAccount>(
      setActiveCarrierAccount,
      activeCarrierAccount
    );

  const handleAutocompleteChange =
    (name: string) => (_e: any, value: string | null) => {
      setActiveCarrierAccount((old) => ({ ...old, [name]: value }));
    };

  const handleCountryChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setActiveCarrierAccount({
        ...activeCarrierAccount,
        billingCountry: _.get(value, 'code', ''),
      });
    },
    [setActiveCarrierAccount, activeCarrierAccount]
  );

  const handleDefaultFlagChanged = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActiveCarrierAccount((old) => ({
        ...old,
        defaultFlag: checked,
      }));
    },
    [setActiveCarrierAccount]
  );

  return (
    <Modal
      isLoadingContent={isLoading}
      open={modalVisible}
      title={
        !activeCarrierAccount.id || activeCarrierAccount.id < 0
          ? 'New Carrier Account'
          : 'Edit Carrier Account'
      }
      onCancelClicked={onClose}
      onApplyClicked={onSaveChanges}
      applyLabel={activeCarrierAccount.id! > 0 ? 'Update' : 'Add'}
      maxWidth="sm"
      dataQa="customer-carrier-account"
    >
      <Box mb={2}>
        <Grid container item spacing={2}>
          <Grid item xs={8}>
            <TextField
              className="redesign"
              variant="standard"
              label="Name"
              name="name"
              placeholder="Enter name"
              value={activeCarrierAccount.name}
              onChange={handleTextFieldChange}
              error={!!validationErrors.name}
              dataQa="customer-carrier-name"
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex' }}>
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  className="redesign"
                  inputProps={
                    {
                      'data-qa': `customer-carrier-account-defaultFlag`,
                    } as any
                  }
                  checked={Boolean(activeCarrierAccount.defaultFlag)}
                  name="defaultFlag"
                  color="primary"
                  onChange={handleDefaultFlagChanged}
                />
              }
              label="Default"
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              label="Carrier"
              placeholder="Select carrier"
              options={Object.values(CustomerCarriersAccountType)}
              value={activeCarrierAccount.carrier}
              onChange={handleAutocompleteChange('carrier')}
              error={!!validationErrors.carrier}
              dataQa="customer-carrier-account-carrier"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              label="Account Number"
              name="accountNumber"
              placeholder="Enter account number"
              value={activeCarrierAccount.accountNumber}
              onChange={handleTextFieldChange}
              error={!!validationErrors.accountNumber}
              dataQa="customer-carrier-account-number"
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              label="Billing Type"
              placeholder="Select billing type"
              options={types}
              value={activeCarrierAccount.billingType}
              onChange={handleAutocompleteChange('billingType')}
              error={!!validationErrors.billingType}
              dataQa="customer-carrier-billing-type"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              label="Billing ZIP"
              placeholder="Enter billing ZIP code"
              name="billingZip"
              value={activeCarrierAccount.billingZip}
              onChange={handleTextFieldChange}
              error={!!validationErrors.billingZip}
              dataQa="customer-carrier-billing-zip"
            />
          </Grid>
          <Grid item xs={6}>
            <CountryAutocomplete
              value={activeCountry}
              placeholder="Select country"
              onChange={handleCountryChange}
              error={!!validationErrors.billingCountry}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(CarrierAccountAddModal);
