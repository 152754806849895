import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid } from '@mui/material';
import AutoComplete from '@mui/material/Autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { postRepresentative } from 'services/representatives/api';
import {
  fetchRepresentatives,
  getRepresentatives,
  initialRepresentative,
  Representative,
} from 'services/representatives';
import { activeUserHasPermission } from 'services/user/redux';

import { RepresentativesAutocompleteProps } from './types';
import {
  representativesAutocompleteFilterOptions,
  representativesAutocompleteGetOptionLabel,
  yupSchema,
} from './helpers';

const RepresentativesAutocomplete: React.FC<
  RepresentativesAutocompleteProps
> = (props) => {
  const {
    onChange,
    dataQa,
    placeholder,
    required,
    label,
    disableAdd,
    value,
    error,
    permissions = [],
    disabled,
  } = props;

  const dispatch = useDispatch();

  const { items: representatives } = useSelector(getRepresentatives);

  const [showModal, setShowModal] = useState(false);
  const [formRepresentative, setFormRepresentative] = useState<Representative>(
    initialRepresentative
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const handleChange = (e: any, representative: Representative | null) => {
    if (
      representative &&
      (representative.id === null || representative.id < 0)
    ) {
      setFormRepresentative((old) => ({
        ...old,
        firstName: representative.firstName,
      }));
      setErrors({});
      setShowModal(true);
      return;
    }

    onChange(representative);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormRepresentative(initialRepresentative);
  };

  const handleApplyModal = async () => {
    if (!validateYup(formRepresentative, yupSchema, setErrors)) {
      return;
    }

    setIsLoading(true);

    try {
      const newRepresentative = await postRepresentative(formRepresentative);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchRepresentatives());
      onChange(newRepresentative);
    } catch {
      setIsLoading(false);
      return;
    }

    setShowModal(false);
    setIsLoading(false);
    setFormRepresentative(initialRepresentative);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormRepresentative,
    formRepresentative
  );

  return (
    <>
      <AutoComplete
        className={'redesign'}
        options={representatives}
        onChange={handleChange}
        value={value}
        disabled={isDisabled}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;
          const endAdornmentWithoutClear = {
            ...endAdornment,
            props: {
              ...endAdornment.props,
              children: [null, endAdornment.props.children[1]],
            },
          };

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornmentWithoutClear}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        filterOptions={representativesAutocompleteFilterOptions(!!disableAdd)}
        getOptionLabel={representativesAutocompleteGetOptionLabel}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        disableClearable={required}
        autoSelect
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
      <Modal
        open={showModal}
        title="New Representative"
        onCancelClicked={handleCloseModal}
        onApplyClicked={handleApplyModal}
        isLoadingContent={isLoading}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              name="firstName"
              label="First name"
              value={formRepresentative.firstName}
              onChange={handleTextFieldChange}
              required
              error={!!errors.firstName}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              name="middleName"
              label="Middle name"
              value={formRepresentative.middleName}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              name="lastName"
              label="Last name"
              value={formRepresentative.lastName}
              onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              name="initials"
              label="Initials"
              value={formRepresentative.initials}
              onChange={handleTextFieldChange}
              required
              error={!!errors.initials}
            />
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default memo(RepresentativesAutocomplete);
