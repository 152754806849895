import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import { getUoms, Uom } from 'services/uoms';
import { UomAutocomplete } from 'ui/components/Autocomplete/UomAutocomplete';
import { Vendor } from 'services/vendors';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { VendorAutocomplete } from 'ui/components/Autocomplete/VendorAutocomplete';
import { PermissionType } from 'services/permissions';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { VendorItemModalProps } from './types';
import { removeUomsWithNoConversion } from './helpers';

const yupSchema = yup.object().shape({
  vendorId: yup.number(),
  name: yup.string(),
  cost: yup.number(),
  defaultUomId: yup.number(),
});

const VendorItemModal: React.FC<VendorItemModalProps> = (props) => {
  const {
    show,
    onSave,
    onClose,
    vendorItem,
    setVendorItem,
    isLoading = false,
    defaultUOMId,
  } = props;

  const [errors, setErrors] = useState<Errors>({});
  const { items: uoms } = useSelector(getUoms);

  const selectedUom = useMemo(
    () => uoms.find((uom) => uom.id === vendorItem.defaultUomId),
    [uoms, vendorItem.defaultUomId]
  );

  useEffect(() => {
    if (show) {
      setVendorItem(vendorItem);
      setErrors({});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setVendorItem,
    vendorItem
  );
  const handleNumberFieldChange = useHandleTextFieldChange(
    setVendorItem,
    vendorItem,
    true
  );

  const handleVendorChange = (vendor: Vendor | null) => {
    setVendorItem((old) => ({
      ...old,
      vendorId: vendor ? vendor.id : null,
      vendor,
    }));
  };

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const name = event.target.name;
      setVendorItem((prevVendorItem) => ({
        ...prevVendorItem,
        [name]: checked,
      }));
    },
    [setVendorItem]
  );

  const handleUomChange = useCallback(
    (uom: Uom | null) => {
      setVendorItem((old) => ({
        ...old,
        defaultUomId: uom ? uom.id : null,
      }));
    },
    [setVendorItem]
  );

  const handleSave = (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (!validateYup(vendorItem, yupSchema, setErrors)) {
      return;
    }
    e.preventDefault();
    onSave(vendorItem);
  };

  return (
    <Modal
      open={show}
      title={vendorItem.id! > 0 ? 'Update Vendor Item' : 'Add Vendor Item'}
      applyLabel={vendorItem.id! > 0 ? 'Add' : 'Update'}
      isLoadingContent={isLoading}
      onApplyClicked={handleSave}
      permissions={[
        vendorItem.id! > 0
          ? PermissionType.ItemsEdit
          : PermissionType.ItemsCreate,
      ]}
      onCancelClicked={onClose}
      dataQa="vendor-item-modal"
    >
      <Box mb={2}>
        <Grid container item spacing={1}>
          <Grid item xs={4}>
            <VendorAutocomplete
              label="Vendor"
              required
              placeholder="Select Vendor"
              value={vendorItem.vendor}
              onChange={handleVendorChange}
              error={!!errors.vendorId}
              expands={[
                'vendorAddresses.vendorAddressContacts',
                'vendorItems.item.images',
                'tags',
              ]}
              dataQa="vendor-item-modal-vendor"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Vendor Item Name"
              placeholder="Vendor Item Name"
              autoComplete="nope"
              fullWidth
              name="name"
              value={vendorItem.name}
              required
              onChange={handleTextFieldChange}
              error={!!errors.name}
              dataQa="vendor-item-modal-item-name"
            />
          </Grid>
          <Grid item xs={4}>
            <CurrencyField
              label="Cost"
              placeholder="Cost"
              name="cost"
              fullWidth
              value={vendorItem.cost}
              required
              allowNegative
              onChange={handleTextFieldChange}
              error={!!errors.cost}
              dataQa="vendor-item-modal-item-cost"
              decimalPlaces={6}
            />
          </Grid>
          <Grid item xs={4}>
            <UomAutocomplete
              placeholder="Select UOM"
              label="UOM"
              required
              onChange={handleUomChange}
              value={selectedUom || null}
              error={!!errors.defaultUomId}
              reduceOptions={removeUomsWithNoConversion(defaultUOMId ?? null)}
              dataQa="vendor-item-modal-uom"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="number"
              label="Lead Time"
              placeholder="Lead Time"
              autoComplete="nope"
              fullWidth
              name="leadTime"
              value={vendorItem.leadTime}
              onChange={handleNumberFieldChange}
              error={!!errors.leadTime}
              dataQa="vendor-item-modal-lead-time"
              disableDebounce={true}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="number"
              label="Minimum Quantity"
              placeholder="Minimum Quantity"
              autoComplete="nope"
              fullWidth
              name="minimumQuantity"
              value={vendorItem.minimumQuantity}
              onChange={handleNumberFieldChange}
              disableDebounce={true}
              error={!!errors.minimumQuantity}
              dataQa="vendor-item-modal-minimum-quantity"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              className="redesign"
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(vendorItem.defaultFlag)}
                  name="defaultFlag"
                  color="primary"
                  onChange={handleCheckboxChange}
                  data-qa="vendor-item-modal-default-checkbox"
                />
              }
              label="Default"
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(VendorItemModal);
