import React, {
  useCallback,
  useMemo,
  memo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Typography, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ImageDropZone } from 'ui/components/Image/ImageDropZone';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import {
  DIMENSIONS_UNITS,
  WEIGHT_UNITS,
  ItemImage,
  ItemType,
  Item,
  resolveItemUomConversions,
  DimensionUnit,
  WeightUnit,
} from 'services/items';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { useHandleTextFieldChange } from 'services/forms';
import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import { Class } from 'services/classes';
import { replaceValueInCollection, USCountryString } from 'helpers';
import { Tag } from 'services/tags';
import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
  useCustomFields,
} from 'services/customFields';
import { ChannelTaxType, TaxRate } from 'services/taxRates';
import { Errors } from 'services/forms/validation';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { UomAutocomplete } from 'ui/components/Autocomplete/UomAutocomplete';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { AverageCostModal } from 'ui/modules/materials/pages/InventoryPage/components/ItemDetailsCard/components/InventoryHeroStatus/components/AverageCostModal';
import { getSettingsCompany } from 'services/settings/company';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';

import { FBOAccountFields } from './FBOAccountFields';
import { resolveDuplicateTags } from './helpers';
import { editItemPermissions } from '../../helpers';
import { isSkuFieldValid } from '../../validations';

import { IconNames, themeRestyle } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';
import { GeneralTabProps } from './types';
import FBOItemUomConversions from './components/ItemUomConversions/FBOItemUomConversions';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { Box } from '@mui/system';
import { PermissionType } from 'services/permissions';

const FBOGeneralTab: React.FC<GeneralTabProps> = (props) => {
  const {
    validationErrors,
    customFieldsErrors,
    oldState,
    item,
    setItem,
    isShippingOrServiceOrOverheadOrLaborItem,
  } = props;

  const { items: uoms } = useSelector(getUoms);
  const companySettings = useSelector(getSettingsCompany);

  const [images, setImages] = useState<ItemImage[]>([]);
  const [showAddTagModal, setShowAddTagModal] = useState<boolean>(false);
  const [activeTag, setActiveTag] = useState<Tag | null>(null);
  const firstInputElement = useRef<HTMLInputElement>(null);
  const [showAverageCostModal, setShowAverageCostModal] =
    useState<boolean>(false);
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [skuValidationErrors, setSkuValidationErrors] = useState<Errors>({});

  const setCustomFields = useCustomFields<Item>(setItem);

  const handleCheckboxChange = useHandleCheckboxChange(setItem);

  const editPermissions = editItemPermissions(item);

  const disabledUomAutocomplete = useMemo(
    () => item.itemType === ItemType.Shipping,
    [item.itemType]
  );
  const selectedDimensionUnit = useMemo(
    () => DIMENSIONS_UNITS.find((u) => u.name === item.dimensionUnit) || null,
    [item.dimensionUnit]
  );
  const selectedWeightUnit = useMemo(
    () => WEIGHT_UNITS.find((u) => u.name === item.weightUnit) || null,
    [item.weightUnit]
  );
  useEffect(() => {
    isSkuFieldValid(item, setSkuValidationErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.sku]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (item.id === ActiveItemIdState.New) {
      (async () => {
        const customFields = (await fetchCustomFieldsAPI({}, ObjectType.Item))
          .data;
        if (oldState.current) {
          oldState.current.customFields = customFields;
        }
        setCustomFields(customFields);
      })();
    }

    // autofocus name textfield when open details card on existing item
    if (item.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }

    setImages(item.imageLinkList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id]);

  useEffect(() => {
    // checks whether the uom autocomplete is disabled in which case locks the UOM to Each
    if (disabledUomAutocomplete) {
      setItem({ ...item, defaultUomId: EACH_UOM_ID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledUomAutocomplete]);

  const selectedUom = useMemo(
    () => uoms.find((u) => u.id === item.defaultUomId) || null,
    [uoms, item]
  );

  const isBundleItem = useMemo(
    () => item.itemType === ItemType.Bundle,
    [item.itemType]
  );

  const isInventoryItem = useMemo(
    () => item.itemType === ItemType.Inventory,
    [item.itemType]
  );
  const averageCost = useMemo(() => {
    return item && item.cost ? item.cost : 0;
  }, [item]);

  const handleTextFieldChange = useHandleTextFieldChange(setItem, item);
  const handleNumberFieldChange = useHandleTextFieldChange(setItem, item, true);

  const handleAddImagesClicked = useCallback(() => {
    setImages(item.imageLinkList);
  }, [item.imageLinkList]);

  const handleCancelImagesClicked = useCallback(() => {
    setImages(item.imageLinkList);
  }, [item.imageLinkList]);

  const handleSaveImagesClicked = useCallback(() => {
    setItem((prevItem) => ({ ...prevItem, imageLinkList: images }));
  }, [images, setItem]);

  const handleAutocompleteChange =
    (name: string, defaultValue: any, value?: string) => (_e: any, v: any) => {
      if (!v) {
        setItem({ ...item, [name]: defaultValue });
        return;
      }

      setItem({ ...item, [name]: value ? v[value] : v });

      if (name === 'itemType' && v === ItemType.Labor) {
        const hrUom = uoms.find((u) => u.abbreviation === 'hr');
        setItem((old) => ({
          ...old,
          defaultUom: hrUom ? hrUom : old.defaultUom,
          defaultUomId: hrUom ? hrUom.id : old.defaultUomId,
        }));
      }

      if (
        name === 'itemType' &&
        (v === ItemType.Labor ||
          v === ItemType.Service ||
          v === ItemType.Shipping ||
          v === ItemType.Overhead)
      ) {
        setItem((old) => ({
          ...old,
          height: null,
          weight: null,
          width: null,
          length: null,
        }));
      }
    };

  const handleTaxRateChange = useCallback(
    (taxRate: TaxRate | null) => {
      setItem((old) => ({
        ...old,
        salesTax: taxRate,
        salesTaxId: taxRate ? taxRate.id : null,
      }));
    },
    [setItem]
  );

  const handleUomChange = useCallback(
    (value: Uom | null) => {
      setItem((old) => ({
        ...old,
        defaultUom: value,
        defaultUomId: value ? value.id : null,
        itemUomConversionList: value
          ? resolveItemUomConversions(value, item.itemUomConversionList)
          : [],
      }));
    },
    [setItem, item.itemUomConversionList]
  );

  const handleClassChange = (accountingClass: Class | null) => {
    setItem({
      ...item,
      accountingClassId: accountingClass ? accountingClass.id : null,
    });
  };

  const handleTagsChange = useCallback(
    (values: Tag[]) => {
      setItem((old) => ({ ...old, tags: values }));
      if (
        values.length &&
        item.saleItemList.length &&
        values.length > item.tags.length
      ) {
        setActiveTag(values[values.length - 1]);
        setShowAddTagModal(true);
      }
    },
    [setItem, item.tags, item.saleItemList]
  );

  const handleTagNameChange = useCallback(
    (tag: Tag) => {
      setItem((old) => ({
        ...old,
        saleItemList: old.saleItemList.map((s) => {
          const saleIndex = s.tags.findIndex((i) => i.id === tag.id);
          if (saleIndex > -1) {
            return {
              ...s,
              tags: replaceValueInCollection(s.tags, tag, saleIndex)!,
            };
          }
          return s;
        }),
      }));
    },
    [setItem]
  );

  const handleAddTagModalClicked = useCallback(() => {
    if (!activeTag) {
      setShowAddTagModal(false);
      return;
    }
    setItem((old) => ({
      ...old,
      saleItemList: old.saleItemList.map((s) => {
        return {
          ...s,
          tags: resolveDuplicateTags(s.tags, activeTag),
        };
      }),
    }));
    setShowAddTagModal(false);
  }, [setItem, activeTag]);

  const handleCancelTagModalClicked = useCallback(() => {
    setActiveTag(null);
    setShowAddTagModal(false);
  }, [setActiveTag, setShowAddTagModal]);

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = item.customFields.findIndex((c) => c.id === customField.id);
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [item.customFields, setCustomFields]
  );

  const handleTagDelete = useCallback(
    (id: number) => {
      setItem((old) => ({
        ...old,
        saleItemList: old.saleItemList.map((s) => {
          const saleIndex = item.tags.findIndex((i) => i.id === id);
          if (saleIndex > -1) {
            return {
              ...s,
              tags: [
                ...s.tags.slice(0, saleIndex),
                ...s.tags.slice(saleIndex + 1),
              ],
            };
          }
          return s;
        }),
      }));
    },
    [item.tags, setItem]
  );

  const handleWeightUnitChange = useCallback(
    (_event: any, value: WeightUnit | null) => {
      setItem((old) => ({
        ...old,
        weightUnit: value ? value.name : null,
      }));
    },
    [setItem]
  );

  const handleDimensionUnitChange = useCallback(
    (_event: any, value: DimensionUnit | null) => {
      setItem((old) => ({
        ...old,
        dimensionUnit: value ? value.name : null,
      }));
    },
    [setItem]
  );
  const handleWarningConfirmClicked = () => {
    setShowAverageCostModal(true);
    setShowWarningDialog(false);
  };

  const handleAverageCostClicked = () => {
    if (item.itemType !== ItemType.Inventory) return;
    setShowWarningDialog(true);
  };

  const handleCostChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setItem((old) => ({
        ...old,
        cost: ev.target.value ? parseFloat(ev.target.value) : 0,
      }));
    },
    [setItem]
  );

  const AverageCost = () => {
    if (companySettings.accountingMethod !== CostingMethod.Average) return null;

    return isBundleItem ? (
      <CurrencyField
        decimalPlaces={6}
        label="Price"
        placeholder="Price"
        name="price"
        autoComplete="nope"
        value={item.price}
        onChange={_.noop}
        permissions={editPermissions}
        disabled
      />
    ) : isInventoryItem ? (
      <Box display="flex">
        <CurrencyField
          decimalPlaces={6}
          label={'Average Cost'}
          name="cost"
          value={item.cost}
          dataQa="item-general-average-cost"
          disabled
        />
        <FBOButton
          color="positive"
          variant="tertiary"
          icon={IconNames.FBOEdit}
          onClick={handleAverageCostClicked}
          data-qa="edit-average-cost-button"
          permissions={[PermissionType.ItemsUpdateAverageCost]}
        />
      </Box>
    ) : (
      <CurrencyField
        decimalPlaces={6}
        label={'Cost'}
        placeholder="Enter cost"
        name="cost"
        autoComplete="nope"
        value={item.cost}
        onChange={handleCostChange}
        allowNegative
        error={!!validationErrors.cost}
        permissions={editPermissions}
        dataQa="item-general-cost"
      />
    );
  };

  return (
    <>
      <Grid
        sx={{ overflowY: 'scroll', padding: '16px' }}
        container
        direction="row"
        alignItems="flex-start"
        spacing={2}
        disableEqualOverflow
      >
        <Grid container xs={3}>
          <Grid xs={12}>
            <ImageDropZone
              images={images}
              setImages={setImages}
              onSave={handleSaveImagesClicked}
              onCancel={handleCancelImagesClicked}
              onAddButtonClicked={handleAddImagesClicked}
              permissions={editPermissions}
            />
          </Grid>
        </Grid>
        <Grid container xs={5}>
          <Grid xs={12}>
            {/*This input is required to prevent Chrome autofill from overriding our settings*/}
            <input hidden={true} name="address" />
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              placeholder="Name"
              name="name"
              autoComplete="nope"
              inputRef={firstInputElement}
              value={item.name}
              onChange={handleTextFieldChange}
              required
              permissions={editPermissions}
              error={!!validationErrors.name}
              dataQa="item-name"
              helperText={validationErrors.name ? 'Name is required' : ''}
            />
          </Grid>
          <Grid xs={6}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Barcode / UPC"
              placeholder="Barcode / UPC"
              name="upc"
              autoComplete="nope"
              value={item.upc}
              permissions={editPermissions}
              onChange={handleTextFieldChange}
              dataQa="item-barcode-upc"
            />
          </Grid>
          <Grid xs={6}>
            <Tooltip title={'Max. characters: 255'}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="SKU"
                placeholder="SKU"
                name="sku"
                autoComplete="nope"
                permissions={editPermissions}
                value={item.sku}
                onChange={handleTextFieldChange}
                dataQa="item-sku"
                error={!!skuValidationErrors.sku}
              />
            </Tooltip>
          </Grid>
          {!isShippingOrServiceOrOverheadOrLaborItem && (
            <>
              <Grid xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  placeholder="Length"
                  label="Length"
                  name="length"
                  autoComplete="nope"
                  value={item.length}
                  onChange={handleNumberFieldChange}
                  disableDebounce={true}
                  permissions={editPermissions}
                  dataQa="item-length"
                />
              </Grid>
              <Grid xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  placeholder="Width"
                  label="Width"
                  name="width"
                  autoComplete="nope"
                  value={item.width}
                  onChange={handleNumberFieldChange}
                  disableDebounce={true}
                  permissions={editPermissions}
                  dataQa="item-width"
                />
              </Grid>
              <Grid xs={12}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  placeholder="Weight"
                  label="Weight"
                  name="weight"
                  autoComplete="nope"
                  value={item.weight}
                  onChange={handleNumberFieldChange}
                  disableDebounce={true}
                  permissions={editPermissions}
                  dataQa="item-weight"
                />
              </Grid>
            </>
          )}
          <Grid xs={6}>
            <UomAutocomplete
              placeholder="Select UOM"
              onChange={handleUomChange}
              value={selectedUom}
              label="UOM"
              disabled={disabledUomAutocomplete}
              required
              permissions={editPermissions}
              error={!!validationErrors.defaultUomId}
              helperText={
                validationErrors.defaultUomId ? 'UOM is required' : ''
              }
              dataQa="item-uom"
            />
          </Grid>
          <Grid xs={6}>{AverageCost()}</Grid>
          {companySettings.country === USCountryString && (
            <Grid xs={12} mb={themeRestyle.spacing(6)}>
              <FormControlLabel
                className="redesign"
                control={
                  <Checkbox
                    name="taxable"
                    className="redesign"
                    size="small"
                    checked={Boolean(item.taxable)}
                    onChange={handleCheckboxChange}
                    defaultChecked={false}
                    color="primary"
                    inputProps={
                      {
                        'data-qa': 'taxable',
                      } as any
                    }
                    disableRipple
                  />
                }
                label="Taxable"
              />
            </Grid>
          )}
        </Grid>
        <Grid container xs={4}>
          <Grid xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Description"
              placeholder="Description"
              name="description"
              autoComplete="nope"
              value={item.description}
              onChange={handleTextFieldChange}
              dataQa="item-description"
              rows={6}
              permissions={editPermissions}
              multiline
              fullWidth
              sx={{ height: '130px' }} // 2px adjustment so form elements line up correctly
            />
          </Grid>
          {!isShippingOrServiceOrOverheadOrLaborItem && (
            <>
              <Grid xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="number"
                  placeholder="Height"
                  label="Height"
                  name="height"
                  autoComplete="nope"
                  value={item.height}
                  onChange={handleNumberFieldChange}
                  disableDebounce={true}
                  permissions={editPermissions}
                  dataQa="item-height"
                />
              </Grid>
              <Grid xs={6}>
                <Autocomplete
                  className="redesign"
                  placeholder="Dimension"
                  onChange={handleDimensionUnitChange}
                  label="Dimension"
                  options={DIMENSIONS_UNITS}
                  getOptionLabel={(u) => u.name}
                  value={selectedDimensionUnit}
                  permissions={editPermissions}
                  dataQa="dimension-unit"
                />
              </Grid>
              <Grid xs={12}>
                <Autocomplete
                  className="redesign"
                  value={selectedWeightUnit}
                  placeholder="Unit"
                  onChange={handleWeightUnitChange}
                  label="Weight Unit"
                  getOptionLabel={(u) => u.name}
                  options={WEIGHT_UNITS}
                  permissions={editPermissions}
                  dataQa="item-weight-unit"
                />
              </Grid>
            </>
          )}
          <Grid xs={6}>
            <Autocomplete
              className="redesign"
              placeholder="Select Type"
              onChange={handleAutocompleteChange('itemType', '')}
              value={item.itemType}
              label="Type"
              required
              disabled={item.id !== null && item.id > 0}
              permissions={editPermissions}
              options={Object.values(ItemType)}
              error={!!validationErrors.itemType}
              helperText={validationErrors.itemType ? 'Type is required' : ''}
              dataQa="item-type"
            />
          </Grid>
          <Grid xs={6}>
            <TaxRatesAutocomplete
              label="Purchase Tax"
              placeholder="Select tax"
              value={item.salesTaxId}
              onChange={handleTaxRateChange}
              permissions={editPermissions}
              error={!!validationErrors.salesTaxId}
              taxRateVariant={TaxRateVariants.Percentage}
              channelTaxType={ChannelTaxType.Purchase}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} mb={themeRestyle.spacing(6)}>
          <FBOItemUomConversions
            setItem={setItem}
            item={item}
            validationErrors={validationErrors}
            // selectedUomConversions={selectedUomConversions}
          />
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12}>
            <Typography>Custom Fields</Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} mb={themeRestyle.spacing(6)}>
          <FBOCustomFields
            customFields={item.customFields}
            onFieldChange={customFieldChanged}
            errors={customFieldsErrors}
            permissions={editPermissions}
            dataQa="items-general-tab"
          />
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              label="Alert Notes"
              type="text"
              placeholder="Enter alert notes"
              name="alertNotes"
              multiline
              value={item.alertNotes}
              permissions={editPermissions}
              onChange={handleTextFieldChange}
              rows={6} // TODO : fix when MUI is migrated from 4->5
              dataQa="item-alert-notes"
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              label="Notes"
              type="text"
              placeholder="Enter notes"
              name="details"
              multiline
              value={item.details}
              permissions={editPermissions}
              onChange={handleTextFieldChange}
              rows={6} // TODO : fix when MUI is migrated from 4->5
              dataQa="item-notes"
            />
          </Grid>
          <Grid xs={12}>
            <TagsAsyncAutocomplete
              label="Tags"
              value={item.tags}
              permissions={editPermissions}
              onChange={handleTagsChange}
              onTagNameChange={handleTagNameChange}
              onTagDelete={handleTagDelete}
              dataQa="item-tag"
            />
          </Grid>
          <FBOAccountFields item={item} setItem={setItem} />
          <Grid xs={6}>
            <ClassAutocomplete
              value={item.accountingClassId}
              onChange={handleClassChange}
              label="Accounting Class"
              placeholder="Select a class"
              permissions={editPermissions}
              dataQa="item-class"
            />
          </Grid>
        </Grid>
      </Grid>
      <ConfirmationModal
        open={showAddTagModal}
        title={'Add tag to Sale Items'}
        body={'Would you like to include this tag on Sale Item as well?'}
        onCancelClicked={handleCancelTagModalClicked}
        onConfirmClicked={handleAddTagModalClicked}
        confirmLabel="Add"
      />
      <AverageCostModal
        isOpen={showAverageCostModal}
        setIsOpen={setShowAverageCostModal}
        item={item}
        averageCost={averageCost}
        setItem={setItem}
      />
      <ConfirmationModal
        open={showWarningDialog}
        title={`Warning`}
        body={`Modifying your Average Cost of Goods Sold can have significant impact on your profit calculations and taxes your business is required to pay. We recommend that you consult with your accountant before making any changes to the Average Cost of Goods Sold. If you choose to proceed, Fishbowl Online cannot recover your original Cost of Goods Sold information. This will also affect any outstanding receipts if you reconcile for a price other than what the Purchase Order states. Do you wish to continue?`}
        onCancelClicked={() => setShowWarningDialog(false)}
        onConfirmClicked={handleWarningConfirmClicked}
        confirmLabel="Yes"
      />
    </>
  );
};

export default memo(FBOGeneralTab);
