import React, { memo, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { InventoryHeroModal } from 'ui/components/Modal/InventoryHeroModal';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { QuantityType } from 'services/inventory';
import { getSettingsCompany } from 'services/settings/company';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';

import { InventoryHeroStatusProps } from './types';
import { useInventoryHeroStatusStyle } from './styled';
import { AverageCostModal } from './components/AverageCostModal';
import { getCurrencySymbol } from 'ui/components/Autocomplete/CountryAutocomplete/helpers';
import { roundToDecimals } from 'helpers';

import { IconNames, colorPalette } from 'ui/theme';
import FBOInventoryHeroStatusItem from './components/FBOInventoryHeroStatusItem/FBOInventoryHeroStatusItem';
import { Icon } from 'ui/components/Icon';
import { useFlags } from 'helpers/useFlags';

const FBOInventoryHeroStatus: React.FC<InventoryHeroStatusProps> = ({
  item,
  itemInventory,
  setItem,
  triggerFetchInventory,
}) => {
  const classes = useInventoryHeroStatusStyle();
  const flags = useFlags();

  const companySettings = useSelector(getSettingsCompany);

  const homeCurrencySymbol = useMemo(
    () => getCurrencySymbol(companySettings.homeCurrency?.country || ''),
    [companySettings]
  );

  const [showInventoryHeroModal, setShowInventoryHeroModal] =
    useState<boolean>(false);
  const [showAverageCostModal, setShowAverageCostModal] =
    useState<boolean>(false);
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [quantityType, setQuantityType] = useState<QuantityType | null>(null);
  const [modalTitle, setModalTitle] = useState<string>('');

  const averageCost = useMemo(() => {
    return item?.cost ? roundToDecimals(item.cost).toString() : '0.00';
  }, [item]);

  const handleClose = () => {
    setShowInventoryHeroModal(false);
  };

  const handleWarningConfirmClicked = () => {
    setShowAverageCostModal(true);
    setShowWarningDialog(false);
  };

  const calculateTotalCost = (totalCost: number) => roundToDecimals(totalCost);
  return (
    <>
      <Box
        p={4}
        textAlign="center"
        style={{
          borderBottom: `1px solid ${colorPalette.redesign.background3}`,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container gap={2} justifyContent={'space-between'}>
              <Grid item xs className={classes.gridItem}>
                <FBOInventoryHeroStatusItem
                  icon={IconNames.FBOWarehouse}
                  value={_.get(itemInventory, 'totalQty', '0')}
                  title="On Hand"
                  dataQa="on-hand"
                />
              </Grid>
              <Grid item xs className={classes.gridItem}>
                <FBOInventoryHeroStatusItem
                  icon={IconNames.FBORightCircle}
                  value={_.get(itemInventory, 'allocatedQty', '0')}
                  title="Allocated"
                  buttonIcon={IconNames.FBOCaratRightSmall}
                  onClick={() => {
                    setShowInventoryHeroModal(true);
                    setModalTitle('Allocated Item Quantity');
                    setQuantityType(QuantityType.Allocated);
                  }}
                  dataQa="allocated"
                />
              </Grid>
              <Grid item xs className={classes.gridItem}>
                <FBOInventoryHeroStatusItem
                  icon={IconNames.FBOLock}
                  value={_.get(itemInventory, 'committedQty', '0')}
                  title="Committed"
                  buttonIcon={IconNames.FBOCaratRightSmall}
                  onClick={() => {
                    setShowInventoryHeroModal(true);
                    setModalTitle('Committed Item Quantity');
                    setQuantityType(QuantityType.Committed);
                  }}
                  dataQa="committed"
                />
              </Grid>
              <Grid item xs className={classes.gridItem}>
                <FBOInventoryHeroStatusItem
                  icon={IconNames.FBOCircleCheck}
                  value={_.get(itemInventory, 'availableQty', '0')}
                  title="Available"
                  dataQa="available"
                />
              </Grid>
              <Grid item xs className={classes.gridItem}>
                <FBOInventoryHeroStatusItem
                  icon={IconNames.FBOShipping}
                  value={_.get(itemInventory, 'incomingQty', '0')}
                  title="Incoming"
                  buttonIcon={IconNames.FBOCaratRightSmall}
                  onClick={() => {
                    setShowInventoryHeroModal(true);
                    setModalTitle('Incoming Item Quantity');
                    setQuantityType(QuantityType.Incoming);
                  }}
                  dataQa="incoming"
                />
              </Grid>
              {flags.showInventoryShortValues ? (
                <Grid item xs className={classes.gridItem}>
                  <FBOInventoryHeroStatusItem
                    icon={IconNames.FBOWarningCircle}
                    value={_.get(itemInventory, 'shortQty', '0')}
                    warn={!!_.get(itemInventory, 'shortQty', 0)}
                    title="Short"
                    dataQa="short"
                  />
                </Grid>
              ) : (
                <Grid item xs></Grid>
              )}
              <Grid item xs container direction={'column'} gap={2}>
                {companySettings.accountingMethod === CostingMethod.Average && (
                  <Grid item xs className={classes.gridItemSubItem}>
                    <Typography
                      data-qa="inventory-hero-status-average-cost-button"
                      variant="body2"
                    >
                      Average
                    </Typography>
                    <Typography
                      className={classes.bold}
                      data-qa="inventory-average-cost"
                    >
                      {`${homeCurrencySymbol}${averageCost}`}
                    </Typography>
                    <Icon
                      name={IconNames.FBOEdit}
                      style={{
                        color: `${colorPalette.redesign.contentLinkBlue}`,
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowWarningDialog(true)}
                    />
                  </Grid>
                )}
                <Grid item xs className={classes.gridItemSubItem}>
                  <Typography
                    data-qa="inventory-hero-status-average-cost-button"
                    variant="body2"
                  >
                    Total
                  </Typography>
                  <Typography
                    className={classes.bold}
                    data-qa="inventory-total-cost"
                  >
                    {`${homeCurrencySymbol}${calculateTotalCost(
                      itemInventory.totalCost
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <InventoryHeroModal
        isOpen={showInventoryHeroModal}
        handleClose={handleClose}
        item={item}
        quantityType={quantityType}
        title={modalTitle}
      />
      <AverageCostModal
        isOpen={showAverageCostModal}
        setIsOpen={setShowAverageCostModal}
        item={item}
        averageCost={parseFloat(averageCost)}
        setItem={setItem}
        triggerFetchInventory={triggerFetchInventory}
      />
      <ConfirmationModal
        open={showWarningDialog}
        title={`Warning`}
        body={`Modifying your Average Cost of Goods Sold can have significant impact on your profit calculations and taxes your business is required to pay. We recommend that you consult with your accountant before making any changes to the Average Cost of Goods Sold. If you choose to proceed, Fishbowl Online cannot recover your original Cost of Goods Sold information. This will also affect any outstanding receipts if you reconcile for a price other than what the Purchase Order states. Do you wish to continue?`}
        onCancelClicked={() => setShowWarningDialog(false)}
        onConfirmClicked={handleWarningConfirmClicked}
        confirmLabel="Yes"
        cancelLabel="Cancel"
      />
    </>
  );
};

export default memo(FBOInventoryHeroStatus);
