import React, { useState } from 'react';
import {
  AccordionContainer,
  AccordionContent,
  AccordionHeader,
} from './styled';
import { AccordionProps } from './types';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
const Accordion: React.FC<AccordionProps> = ({
  header,
  details,
  expand = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(expand);

  const toggleAccordion = () => setIsExpanded((prev) => !prev);
  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggleAccordion}>
        <div style={{ flexGrow: 1 }}>{header}</div>

        <FBOButton
          variant="tertiary"
          color="neutral"
          size="large"
          icon={isExpanded ? 'ChevronUp' : 'ChevronDown'}
          onClick={(e) => {
            e.stopPropagation();
            toggleAccordion();
          }}
          data-qa="expand-toggle-button"
          sx={{ fontSize: '32px' }}
        />
      </AccordionHeader>
      <AccordionContent expanded={isExpanded}>{details}</AccordionContent>
    </AccordionContainer>
  );
};

export default Accordion;
