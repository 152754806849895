import styled from 'styled-components';

export const AccordionContainer = styled.div`
  border: none;
  width: 100%;
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  cursor: pointer;
  border-bottom: 1px solid #e2e8ee;
  border-top: 1px solid #e2e8ee;
`;

export const AccordionContent = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
  padding: ${({ expanded }) => (expanded ? '1rem' : '0')};
  border-radius: 5px;
`;
