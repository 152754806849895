import _ from 'lodash';
import React, {
  memo,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from '@mui/material';

import {
  PurchaseOrder,
  createPurchaseOrder,
  updatePurchaseOrder,
  deletePurchaseOrder,
  initialPurchaseOrder,
  fetchPurchaseOrderById,
  issuePurchaseOrder,
  unissuePurchaseOrder,
  PurchaseOrderItem,
  getPurchaseOrderId,
  restorePurchaseOrder,
} from 'services/purchaseOrders';
import { getActiveUser } from 'services/user';
import { Errors, validateYup } from 'services/forms/validation';
import { getSettingsCompany } from 'services/settings/company/redux';
import { getSettingsPurchaseOrder } from 'services/settings/purchaseOrders';
import { useUrlQueryObject } from 'services/url';
import { showNotification } from 'services/api';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';
import { ReportId } from 'services/reports';
import { deleteDocuments } from 'services/documents/api';
import { getLocations, Location } from 'services/locations';
import { TabPanel } from 'ui/components/TabPanel';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';

import {
  PurchaseOrderDetailsCardProps,
  PurchaseOrderActiveIdState,
} from './types';
import { PurchaseOrderItems, PurchaseOrderDocumentsTab } from './components';
import {
  transformToDuplicateAsCreditReturn,
  transformToDuplicatedItem,
} from './helpers';
import {
  resolvePurchaseRowSchema,
  yupPurchaseOrderSchema,
} from './validations';
import { PurchaseOrderTitleBar } from './components/PurchaseOrderTitleBar';
import { transformInitialPurchaseOrder } from './components/GeneralTab/helpers';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Routes } from 'ui/modules/purchasing/navigation';
import { FetchOptions } from 'ui/components/Page/WithSearchResults';
import { DismissibleModal } from 'ui/components/Modal/DismissableModal/DismissibleModal';
import { noTaxAddedBodyContent, noTaxAddedModalTitle } from './consts';
import FBOGeneralTab from './components/GeneralTab/FBOGeneralTab';
import FBODetailsTab from './components/DetailsTab/FBODetailsTab';
import FBOPurchaseEmailModal from './components/EmailModal/FBOPurchaseEmailModal';

const PurchaseOrderDetailsCard = (props: PurchaseOrderDetailsCardProps) => {
  const {
    activePurchaseOrderId,
    nextPoNumber,
    setNextPoNumber,
    fetchSearchResult,
    onClose,
    clone,
    setClone,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [, setQueryParams] = useUrlQueryObject();

  const activeUser = useSelector(getActiveUser);
  const companySettings = useSelector(getSettingsCompany);
  const poSettings = useSelector(getSettingsPurchaseOrder);
  const { items: locations } = useSelector(getLocations);

  const [activePurchaseOrder, setActivePurchaseOrder] =
    useState<PurchaseOrder>(initialPurchaseOrder);

  const [showNoTaxAddedModal, setShowNoTaxAddedModal] =
    useState<boolean>(false);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [checkModalVisible, setCheckModalVisible] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [rowValidationErrors, setRowValidationErrors] = useState<any[]>([]);
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [showReportModal, setShowReportModal] = useState(false);
  const oldState = useRef<PurchaseOrder | null>(initialPurchaseOrder);

  const grandTotal = useMemo(() => {
    if (!activePurchaseOrder) {
      return 0;
    }
    return activePurchaseOrder.purchaseOrderItemList.reduce(
      (acc: number, item: PurchaseOrderItem) => {
        acc = acc + (item.totalCost || 0);
        return acc;
      },
      0
    );
  }, [activePurchaseOrder]);

  useEffect(() => {
    const asyncFce = async (id: number) => {
      setIsLoading(true);

      try {
        const purchaseOrder = await fetchPurchaseOrderById(id);
        oldState.current = purchaseOrder;
        setActivePurchaseOrder(purchaseOrder);

        if (clone) {
          duplicateClicked();
        }
      } catch (err) {
        const message = getErrorMessage(err);
        logErrorCtx('Error in fetchPurchaseOrderById', {
          error: err as Error,
          component: 'PurchaseOrderDetailsCard',
          description: `Purchase id ${id}`,
        });

        showNotification(`${message} - purchase order couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();

        return;
      }

      setIsLoading(false);
    };

    setActiveTab(0);

    // When adding new purchase order we want to prefill data
    if (
      !activePurchaseOrderId ||
      activePurchaseOrderId === PurchaseOrderActiveIdState.New
    ) {
      const location = locations.find(
        (l: Location) =>
          l.id === _.get(activeUser, 'user.defaultLocationId', null) || null
      );
      const id = PurchaseOrderActiveIdState.New;
      const number = nextPoNumber === -1 ? null : nextPoNumber.toString();
      const buyerId = _.get(activeUser, 'user.id', null);
      const locationId = _.get(activeUser, 'user.defaultLocationId', null);
      const shippingTermId = companySettings.defaultShippingTermId;

      const newPurchaseOrder = transformInitialPurchaseOrder(
        location!,
        number,
        buyerId,
        id,
        locationId,
        shippingTermId,
        poSettings
      );
      oldState.current = newPurchaseOrder;
      setActivePurchaseOrder(newPurchaseOrder);
      return;
    }

    // When duplicating purchase order we want to prefill data
    if (activePurchaseOrderId === PurchaseOrderActiveIdState.Duplicate) {
      const newDuplicatedPurchaseOrder = transformToDuplicatedItem(
        activePurchaseOrder,
        nextPoNumber
      );
      setActivePurchaseOrder(newDuplicatedPurchaseOrder);
      showNotification('You duplicated purchase order', { variant: 'success' });
      setClone(false);
      return;
    }

    // When duplicating as credit return we want to change all sales and misc sales to credit return
    if (
      activePurchaseOrderId ===
      PurchaseOrderActiveIdState.DuplicateAsCreditReturn
    ) {
      const newDuplicatedAsCreditReturn =
        transformToDuplicateAsCreditReturn(activePurchaseOrder);
      setActivePurchaseOrder(newDuplicatedAsCreditReturn);
      showNotification('You duplicated purchase order as credit return order', {
        variant: 'success',
      });
      return;
    }

    // Fetch from server
    asyncFce(activePurchaseOrderId);
    setValidationErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePurchaseOrderId]);

  // update spatial navigation
  useEffect(() => {
    if (
      !activePurchaseOrder.id ||
      activePurchaseOrder.id === PurchaseOrderActiveIdState.New ||
      activePurchaseOrder.id === PurchaseOrderActiveIdState.Duplicate ||
      activePurchaseOrder.id ===
        PurchaseOrderActiveIdState.DuplicateAsCreditReturn
    ) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Purchase));
      return;
    }

    dispatch(
      updateModuleNavigation(ModuleNavigationType.Purchase, {
        purchaseOrderId: activePurchaseOrder.id,
        receiptId: activePurchaseOrder.receipt
          ? activePurchaseOrder.receipt.id
          : undefined,
        pickIds: activePurchaseOrder.picks.map((p) => p.id),
        shipIds: activePurchaseOrder.shipments.map((s) => s.id),
        salesOrderIds:
          (activePurchaseOrder.dropShipSalesOrder && [
            activePurchaseOrder.dropShipSalesOrder.id!,
          ]) ||
          undefined,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePurchaseOrder.status, activePurchaseOrder.id]);

  const validatePoItems = useCallback((PoItems: PurchaseOrderItem[]) => {
    const newErrors: any[] = [];

    const customSetter = (index: number) => (error: any) => {
      newErrors[index] = error;
    };

    const itemsAreValid = PoItems.map((item, index) => {
      const itemSpecificSetter = customSetter(index);
      return validateYup(
        item,
        resolvePurchaseRowSchema(item),
        itemSpecificSetter
      );
    }).every((i) => i);

    setRowValidationErrors(newErrors);

    return itemsAreValid;
  }, []);

  const disMissNoTaxAddedModal = useCallback(() => {
    setShowNoTaxAddedModal(false);
  }, [showNoTaxAddedModal]);

  const saveClicked = useCallback(
    (close?: boolean) => async () => {
      const hasTaxableItemWithoutTaxRate =
        activePurchaseOrder.purchaseOrderItemList.some(
          (item) =>
            item.taxable && (item.taxRate === 0 || item.taxRate === null)
        );

      if (hasTaxableItemWithoutTaxRate) {
        setShowNoTaxAddedModal(true);
      }
      const isValid = validateYup(
        activePurchaseOrder,
        yupPurchaseOrderSchema,
        setValidationErrors
      );
      const arePoItemsValid = validatePoItems(
        activePurchaseOrder.purchaseOrderItemList.filter((i) => !i.deleted)
      );

      const isCustomFieldsValid = validateYup(
        activePurchaseOrder.customFields,
        customFieldsYupSchema,
        setCustomFieldsErrors
      );

      if (!isValid || !arePoItemsValid || !isCustomFieldsValid) {
        setActiveTab(0);
        return false;
      }

      setIsLoading(true);

      const notDeletedDocuments = activePurchaseOrder.documents.filter(
        (d) => !d.deleted
      );
      const deletedDocuments = activePurchaseOrder.documents.filter(
        (d) => d.deleted
      );
      const resolvedPurchaseOrder = {
        ...activePurchaseOrder,
        documents: notDeletedDocuments,
      };

      // Create new Purchase Order
      if (!activePurchaseOrder.id || activePurchaseOrder.id < 0) {
        try {
          await deleteDocuments(deletedDocuments);
        } catch (e) {
          logErrorCtx('Error in Create new Purchase Order', {
            error: e as Error,
            component: 'PurchaseOrderDetailsCard',
          });
        }

        try {
          const newActivePurchaseOrder = await createPurchaseOrder(
            resolvedPurchaseOrder
          );
          oldState.current = newActivePurchaseOrder;
          setActivePurchaseOrder(newActivePurchaseOrder);
          await fetchSearchResult();
          setQueryParams({ activeId: newActivePurchaseOrder.id });
          if (close) {
            setQueryParams({ activeId: null });
            onClose();
            return true;
          }
        } catch {
          setIsLoading(false);
          return false;
        }
        setIsLoading(false);
        return true;
      }

      // Update Purchase Order
      try {
        await deleteDocuments(deletedDocuments);
      } catch (e) {
        logErrorCtx('Error in Update Purchase Order', {
          error: e as Error,
          component: 'PurchaseOrderDetailsCard',
        });
      }

      try {
        const newActivePurchaseOrder = await updatePurchaseOrder(
          resolvedPurchaseOrder
        );
        oldState.current = newActivePurchaseOrder;
        setActivePurchaseOrder(newActivePurchaseOrder);
        await fetchSearchResult();
      } catch {
        setIsLoading(false);
        return false;
      }
      if (close) {
        onClose();
      }
      setIsLoading(false);
      return true;
    },
    [
      activePurchaseOrder,
      fetchSearchResult,
      onClose,
      validatePoItems,
      setQueryParams,
    ]
  );

  const duplicateClicked = useCallback(async () => {
    // We use id -2 to duplicate
    const nextNumber = await getPurchaseOrderId();
    setNextPoNumber(nextNumber);
    setQueryParams({ activeId: PurchaseOrderActiveIdState.Duplicate });
  }, [setQueryParams, setNextPoNumber]);

  const duplicateAsCreditReturnClicked = useCallback(async () => {
    // We use id -3 to duplicate as credit return
    setQueryParams({
      activeId: PurchaseOrderActiveIdState.DuplicateAsCreditReturn,
    });
  }, [setQueryParams]);

  const onDeleteClicked = useCallback(() => setDeleteModalVisible(true), []);

  const issue = useCallback(async () => {
    const purchaseOrderItemsNotDeleted =
      activePurchaseOrder.purchaseOrderItemList.filter((p) => !p.deleted);
    if (_.isEmpty(purchaseOrderItemsNotDeleted)) {
      showNotification('You cannot issue a Purchase Order that has no items.', {
        variant: 'warning',
      });
      return;
    }
    const isValid = validateYup(
      activePurchaseOrder,
      yupPurchaseOrderSchema,
      setValidationErrors
    );
    const arePoItemsValid = validatePoItems(
      activePurchaseOrder.purchaseOrderItemList.filter((i) => !i.deleted)
    );

    const isCustomFieldsValid = validateYup(
      activePurchaseOrder.customFields,
      customFieldsYupSchema,
      setCustomFieldsErrors
    );

    if (!isValid || !arePoItemsValid || !isCustomFieldsValid) {
      setActiveTab(0);
      return;
    }

    setIsLoading(true);

    let purchaseOrder;
    // Create new Purchase Order
    if (!activePurchaseOrder.id || activePurchaseOrder.id < 0) {
      try {
        purchaseOrder = await createPurchaseOrder(activePurchaseOrder, true);
        oldState.current = purchaseOrder;
        setActivePurchaseOrder(purchaseOrder);
        await fetchSearchResult();
        setQueryParams({ activeId: purchaseOrder.id });
      } catch {
        // continue regardless of error
      }
    } else {
      // Update Purchase Order
      try {
        purchaseOrder = await updatePurchaseOrder(activePurchaseOrder, true);
        oldState.current = purchaseOrder;
        setActivePurchaseOrder(purchaseOrder);
        await fetchSearchResult();
      } catch {
        // continue regardless of error
      }
    }

    if (purchaseOrder) {
      setIsLoading(false);

      try {
        const newPurchaseOrder = await issuePurchaseOrder(purchaseOrder);
        oldState.current = newPurchaseOrder;
        setActivePurchaseOrder(newPurchaseOrder);
        await fetchSearchResult();
        setQueryParams({ activeId: newPurchaseOrder.id });
      } catch {
        setIsLoading(false);
        return;
      }

      if (poSettings.promptToSendEmailOnPoIssue) {
        setEmailModalVisible(true);
      }
    }

    setIsLoading(false);
  }, [
    activePurchaseOrder,
    poSettings,
    fetchSearchResult,
    validatePoItems,
    setQueryParams,
  ]);

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restorePurchaseOrder(activePurchaseOrderId!);
        const restoredPurchaseOrder = await fetchPurchaseOrderById(
          activePurchaseOrderId!
        );
        oldState.current = restoredPurchaseOrder;
        setActivePurchaseOrder(restoredPurchaseOrder);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activePurchaseOrderId]
  );

  const yesClicked = useCallback(() => {
    setTimeout(() => setCheckModalVisible(false), 500);
    issue();
  }, [issue]);

  const handleEmailModalClose = useCallback(() => {
    setTimeout(() => setEmailModalVisible(false), 500);
  }, []);

  const issueClicked = useCallback(async () => {
    const vendorMin = _.get(activePurchaseOrder, 'vendor.vendorMin', 0);

    // vendor minimum shouldn't be checked if the balance is bellow zero
    if (grandTotal >= 0 && vendorMin > grandTotal) {
      setCheckModalVisible(true);
      return;
    }

    issue();
  }, [activePurchaseOrder, grandTotal, issue]);

  const unissueClicked = useCallback(async () => {
    setIsLoading(true);

    let purchaseOrder;
    if (!activePurchaseOrder.id || activePurchaseOrder.id < 0) {
      try {
        purchaseOrder = await createPurchaseOrder(activePurchaseOrder, true);
        oldState.current = purchaseOrder;
        setActivePurchaseOrder(purchaseOrder);
        await fetchSearchResult();
        setQueryParams({ activeId: purchaseOrder.id });
      } catch {
        // Ignore error
      }
    } else {
      try {
        purchaseOrder = await updatePurchaseOrder(activePurchaseOrder, true);
        oldState.current = purchaseOrder;
        setActivePurchaseOrder(purchaseOrder);
        await fetchSearchResult();
      } catch {
        // Ignore error
      }
    }

    try {
      const newPurchaseOrder = await unissuePurchaseOrder(activePurchaseOrder);
      oldState.current = newPurchaseOrder;
      setActivePurchaseOrder(newPurchaseOrder);
      await fetchSearchResult();
    } catch {
      // continue regardless of error
    }

    setIsLoading(false);
  }, [activePurchaseOrder]);

  const deleteClicked = useCallback(async () => {
    deleteClickedCallback(
      deletePurchaseOrder,
      activePurchaseOrder,
      history,
      fetchSearchResult,
      setDeleteModalVisible,
      onClose
    );
  }, [activePurchaseOrder, fetchSearchResult, onClose]);

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const emailClicked = useCallback(() => {
    setEmailModalVisible(true);
  }, []);

  return (
    <DetailsCard
      onSubmit={saveClicked(false)}
      isLoading={isLoading}
      state={activePurchaseOrder}
      oldState={oldState}
    >
      <PurchaseOrderTitleBar
        activePurchaseOrder={activePurchaseOrder}
        onSave={saveClicked()}
        onClose={onClose}
        onIssueClicked={issueClicked}
        onUnIssueClicked={unissueClicked}
        emailClicked={emailClicked}
        showReportModal={() => setShowReportModal(true)}
        duplicateClicked={duplicateClicked}
        onDeleteClicked={onDeleteClicked}
        onUndeleteClicked={handleUndeleteClicked}
        duplicateAsCreditReturnClicked={duplicateAsCreditReturnClicked}
      />
      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab label="General" />
        <Tab label="Details" data-qa="purchase-order-details-tab" />
        <Tab label="Documents" />
      </Tabs>
      <TabPanel
        value={activeTab}
        index={0}
        flexGrow
        noSpacing
        style={{ flexDirection: 'column' }}
      >
        <>
          <FBOGeneralTab
            purchaseOrder={activePurchaseOrder}
            setPurchaseOrder={setActivePurchaseOrder}
            validationErrors={validationErrors}
            customFieldsErrors={customFieldsErrors}
            oldState={oldState}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
          <PurchaseOrderItems
            purchaseOrder={activePurchaseOrder}
            setPurchaseOrder={setActivePurchaseOrder}
            validationErrors={validationErrors}
            rowValidationErrors={rowValidationErrors}
            poSettings={poSettings}
            oldState={oldState}
          />
        </>
      </TabPanel>
      <TabPanel value={activeTab} index={1} noSpacing>
        <FBODetailsTab
          purchaseOrder={activePurchaseOrder}
          setPurchaseOrder={setActivePurchaseOrder}
          validationErrors={{}}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2} noSpacing>
        <PurchaseOrderDocumentsTab
          purchaseOrder={activePurchaseOrder}
          setPurchaseOrder={setActivePurchaseOrder}
        />
      </TabPanel>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Purchase Order"
        body={`This will delete '${
          activePurchaseOrder.number || 'PURCHASE ORDER'
        }' and all related purchase order items, are you sure?`}
        onCancelClicked={() => setDeleteModalVisible(false)}
        onConfirmClicked={deleteClicked}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      <ConfirmationModal
        open={checkModalVisible}
        title="Order does not meet vendor minimum requirement"
        body={'Do you still want to continue?'}
        onCancelClicked={() => setCheckModalVisible(false)}
        onConfirmClicked={yesClicked}
        confirmLabel="Yes"
        cancelLabel="Cancel"
      />
      <FBOPurchaseEmailModal
        show={emailModalVisible}
        purchaseOrder={activePurchaseOrder}
        onClose={handleEmailModalClose}
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.PurchaseOrder}
        params={{ purchaseOrderId: activePurchaseOrderId }}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
      <DismissibleModal
        open={showNoTaxAddedModal}
        title={noTaxAddedModalTitle}
        body={noTaxAddedBodyContent}
        onDismiss={disMissNoTaxAddedModal}
      />
    </DetailsCard>
  );
};

export default memo(PurchaseOrderDetailsCard);

export const deleteClickedCallback = async (
  deletePurchaseOrder: (id: number) => Promise<void>,
  activePurchaseOrder: PurchaseOrder,
  history: RouteComponentProps['history'],
  fetchSearchResult: (options?: FetchOptions) => Promise<void>,
  setDeleteModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  onClose: () => void
) => {
  try {
    await deletePurchaseOrder(activePurchaseOrder.id!);
    await fetchSearchResult();

    setDeleteModalVisible(false);
    history.push(Routes.PurchaseOrderPage);
    onClose();
  } catch (e) {
    logErrorCtx('Error in Deleting new Purchase Order', {
      error: e as Error,
      component: 'PurchaseOrderDetailsCard',
    });
  }
};
