import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

import { DataWithPagination } from 'services/api';
import { Costing, getCostingByItemId } from 'services/inventory';
import { Pagination } from 'services/search';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { CONSTING_TABLE_COLUMNS, initialPagination } from './consts';
import { InventoryCostingTabProps } from './types';
import { CostingTableRow } from './components/CostingTableRow';
import { logErrorCtx } from 'app/logging';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';

const InventoryCostingTab: React.FC<InventoryCostingTabProps> = ({
  activeItem,
}) => {
  const [costingData, setCostingData] = useState<DataWithPagination<Costing>>({
    data: [],
    pagination: initialPagination,
  });
  const [isLoading, setIsLoading] = useState(false);

  const title = (
    <>
      Costing Layers{' '}
      <Typography color="textSecondary" display="inline">
        FIFO/LIFO
      </Typography>
    </>
  );

  const getCostingData = async (pagination: Pagination) => {
    setIsLoading(true);
    const expands = ['item.defaultUom'];
    try {
      const res = await getCostingByItemId(activeItem.id!, {
        expands,
        pagination,
      });
      setCostingData(res);
    } catch (error) {
      logErrorCtx('Error in getCostingData', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'InventoryCostingTab',
        title: 'Error in getCostingData',
        description: `Costing Item id ${activeItem.id}`,
      });
    }
    setIsLoading(false);
  };

  const handlePaginationChange = (newPagination: Pagination) => {
    getCostingData(newPagination);
  };

  useEffect(() => {
    getCostingData(initialPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem.id]);

  return (
    <>
      <FBOTitleBar title={title}></FBOTitleBar>
      <ItemsTable
        data={costingData.data.filter((item) => !item.voided)}
        isLoadingData={isLoading}
        columns={CONSTING_TABLE_COLUMNS}
        RenderCustomRow={CostingTableRow}
        selectableItems={false}
        pagination={costingData.pagination}
        onPaginationChange={handlePaginationChange}
        showEnhancedTablePaginationActions
        emptyTableText="When inventory is added the cost information will be displayed here"
      />
    </>
  );
};

export default InventoryCostingTab;
