import _ from 'lodash';

import {
  AutoPurchaseOrderItem,
  createPurchaseOrder,
  getPurchaseOrderId,
  initialPurchaseOrder,
  initialPurchaseOrderItem,
  PurchaseOrder,
} from 'services/purchaseOrders';
import {
  SalesOrderBundleItem,
  SalesOrderItem,
  SalesOrderItemTypes,
} from 'services/salesOrders';
import { Vendor } from 'services/vendors';

export const resolveQuantity = (
  itemId: number | null,
  soItems: SalesOrderItem[]
) => {
  if (itemId) {
    return soItems
      .filter((soi) => soi.saleItem && soi.saleItem.itemId === itemId)
      .reduce((a, b) => {
        return a + (b.quantity || 0);
      }, 0);
  }
  return 0;
};

export const resolveItems = (
  salesItems: (SalesOrderItem | SalesOrderBundleItem)[]
) => {
  const mergedItems = salesItems
    .map((i) => {
      if (
        i.salesOrderItemType === SalesOrderItemTypes.Bundle ||
        i.salesOrderItemType === SalesOrderItemTypes.BundleCreditReturn
      ) {
        return (i as SalesOrderBundleItem).salesOrderItems.map((soi) => ({
          ...soi,
          quantity:
            i.quantity && soi.quantity
              ? i.quantity * soi.quantity
              : soi.quantity,
        }));
      }
      return i;
    })
    .flat();
  return mergedItems;
};

export const createAutoPurchaseOrders = async (
  autoItems: AutoPurchaseOrderItem[],
  locationId: number,
  vendors: Vendor[]
) => {
  const grouped = _.groupBy(autoItems, 'vendorId');
  const newOrders: PurchaseOrder[] = [];

  for (const items of Object.values(grouped)) {
    const item = items[0];
    const vendor = vendors.find((v) => v.id === item.vendorId);

    if (!vendor) {
      continue;
    }

    const nextPoNumber = await getPurchaseOrderId();
    const purchaseOrderItemList = items.map((i) => ({
      ...initialPurchaseOrderItem,
      item: i.item,
      itemId: i.item.id,
      unitCost: i.item.cost,
      quantity: i.quantity,
      uomId: i.item.defaultUomId,
    }));

    newOrders.push({
      ...initialPurchaseOrder,
      locationId: locationId,
      vendorId: vendor.id,
      vendor: vendor,
      number: nextPoNumber.toString(),
      purchaseOrderItemList,
    });
  }

  // set line numbers in po items
  const orderedPurchaseOrders = newOrders.map((o) => ({
    ...o,
    purchaseOrderItemList: o.purchaseOrderItemList.map((item, index) => ({
      ...item,
      lineNumber: index,
    })),
  }));

  return orderedPurchaseOrders;
};

export const createPurchaseOrders = async (purchaseOrders: PurchaseOrder[]) => {
  for (const po of purchaseOrders) {
    await createPurchaseOrder(po);
  }
};
